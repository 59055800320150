/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Link } from '@mui/material';
import { useRouter } from 'next/navigation';
import Icon from 'libs/shared/ui-components/src/lib/icon/ba-icon';
import { serviceDataProps } from 'apps/bayada/app/find-an-office/find-an-office-page';
import { getLocLatLong } from 'apps/bayada/services/office-service';
import { useAppContext } from 'apps/bayada/context/app-context';
import { getNestedSlug } from 'apps/bayada/utils/helper';
import { getServicePageSlug } from 'apps/bayada/services/service-helper';

export interface BreadcrumbProps {
  breadcrumbData: string[];
  isLocationPage: boolean;
  googleMapsApiKey?: string;
  office?: any;
  serviceList?: serviceDataProps[];
}

//  The kebabToTitleCase function takes in a kebab-cased string, and returns a title-cased string.
const kebabToTitleCase = (kebabCaseString: string) => {
  const words = kebabCaseString.split('-');
  const titleCaseWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1)
  );
  const titleCaseString = titleCaseWords.join(' ');
  return titleCaseString;
};

/**
 * The Breadcrumb component is used to display a series of breadcrumbs to
 * indicate the current page's location within a website's structure.
 *
 * The Breadcrumb component takes in an array of breadcrumbs, which are
 * rendered as a series of links. When a breadcrumb is clicked, the
 * component uses the browser's History API to update the current URL to
 * the URL of the breadcrumb clicked.
 *
 * The Breadcrumb component is fully accessible and has the following
 * features:
 *  - The component has a role of "navigation" and an aria-label of "Breadcrumb"
 *  - Each breadcrumb has a role of "link" and an aria-label of "Breadcrumb Link"
 *  - The last breadcrumb in the list has an aria-current attribute set to "page"
 *  - The component has a chevron icon between each breadcrumb, which is
 *    just a styled SVG icon
 */
export function Breadcrumbs(props: BreadcrumbProps) {
  /**
   * This function is used to navigate to a specific page based on the breadcrumb clicked.
   * This function uses the browser's History API to update the current URL to the URL of the breadcrumb clicked.
   */
  const {
    breadcrumbData,
    isLocationPage,
    googleMapsApiKey,
    serviceList,
    office
  } = props || {};
  const router = useRouter();
  const { context } = useAppContext();
  const { userAgent } = context || {};

  if (isLocationPage) {
    breadcrumbData?.splice(-1, 1);
    if (breadcrumbData[2]) {
      breadcrumbData[2] = breadcrumbData[2]?.toUpperCase();
    }
  }

  const GoToPage = (breadcrumb: string) => {
    if (window && window?.location) {
      const windowUrl = window.location.href;
      const targetIndex = breadcrumbData?.findIndex(
        (item) => item === breadcrumb
      );

      if (targetIndex !== -1) {
        const filteredArray =
          targetIndex !== -1
            ? breadcrumbData?.filter((item, index) => index <= targetIndex)
            : [];

        const routerPath = filteredArray.join('/');
        window.location.pathname = routerPath;
      } else {
        window.location.href = windowUrl;
      }
    }
  };

  const locationPageBreadCrumb = async (
    breadcrumb: string,
    breadcrumbData: string[],
    index: number
  ) => {
    let practice;
    let service: string;

    const contentful = context?.ctfClient;
    switch (index) {
      case 0:
        practice = serviceList?.find((data) =>
          breadcrumb
            ?.toLowerCase()
            ?.replace(/[^\w\s]/g, ' ')
            ?.includes(data?.fullName?.toLowerCase()?.replace(/[^\w\s]/g, ' '))
        );

        if (practice) {
          const serviceSlug = getServicePageSlug(practice?.fullName);
          const servicePage = await contentful?.getEntries({
            content_type: 'page',
            'fields.slug': serviceSlug,
            include: 10
          });

          const servicePageSlug = servicePage?.items?.[0]
            ? getNestedSlug(servicePage?.items?.[0])
            : serviceSlug;
          if (userAgent === 'Firefox') {
            window.location.href = `/${servicePageSlug}`;
          } else {
            router.push(`/${servicePageSlug}`);
          }
        }
        break;
      case 1:
        service = kebabToTitleCase(breadcrumbData?.[0]);
        userAgent === 'Firefox'
          ? (window.location.href = `/find-an-office?service=${service?.replace(/ /g, '+')}`)
          : router.push(
              `/find-an-office?service=${service?.replace(/ /g, '+')}`
            );
        break;

      case 2:
        getPlaceDetailsFromState(office?.stateName, '100');
        break;
      case 3:
        getPlaceDetailsFromState(`${breadcrumb}, ${breadcrumbData?.[2]}`, '25');
        break;
    }
  };

  const getPlaceDetailsFromState = async (address: string, radius: string) => {
    const geocodeData = await getLocLatLong(address, googleMapsApiKey);
    const selectedAddress = geocodeData?.results?.[0]?.formatted_address;
    const selectedService = kebabToTitleCase(breadcrumbData?.[0]);
    if (userAgent === 'Firefox') {
      window.location.href = `/find-an-office?address=${selectedAddress?.replace(/ /g, '+')}&service=${selectedService?.replace(/&/g, '+')?.replace(/ /g, '+')}&radius=${radius}`;
    } else {
      router.push(
        `/find-an-office?address=${selectedAddress?.replace(/ /g, '+')}&service=${selectedService?.replace(/&/g, '+')?.replace(/ /g, '+')}&radius=${radius}`
      );
    }
  };

  return (
    <nav role="navigation" aria-label="Breadcrumb" className="min-w-0 flex">
      <ul
        className={`inline-flex items-center min-w-0 overflow-x-auto custom-scrollbar w-full lg:w-auto`}
        aria-label="Breadcrumb"
      >
        {breadcrumbData?.map((item: string, index: number) => (
          <li key={index} className="shrink-0 min-w-0">
            {index !== breadcrumbData?.length - 1 && (
              <div
                className="flex items-center shrink-0 min-w-0"
                aria-label={`breadcrumb-heading-${index}`}
              >
                <Link
                  tabIndex={0}
                  role="link"
                  aria-label="Breadcrumb Link"
                  className={`t-13 cursor-pointer shrink-0 min-w-0 ${office?.isJV ? 'color-ba-blue-600' : ''}`}
                  onClick={() => {
                    !isLocationPage
                      ? GoToPage(item)
                      : locationPageBreadCrumb(item, breadcrumbData, index);
                  }}
                >
                  {kebabToTitleCase(item)}
                </Link>
                {index !== breadcrumbData?.length - 1 && (
                  <Icon
                    iconName="chevronRight"
                    className="svg-icon icon-20 flex items-center justify-center color-ba-gray-425"
                  />
                )}
              </div>
            )}
            {index === breadcrumbData?.length - 1 && (
              <div
                className="flex items-center shrink-0 min-w-0"
                aria-label="breadcrumb-heading"
              >
                <span className="color-ba-gray-425 t-13">
                  {kebabToTitleCase(item)}
                </span>
              </div>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
}

export default Breadcrumbs;
