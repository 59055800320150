'use client';
import React, { useCallback, useRef } from 'react';
import { CarouselItem } from '../static-carousel-container';
import { Container, Stack } from '@mui/material';
import CarouselWrapper from '../carousel-wrapper';

import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  Position,
  HeadingValue
} from '@bayada/interfaces';
import { timelineProperties } from '../carousel-constants';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface TimelineCarouselProps {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  backgroundColor?: BackgroundColor;
  items?: CarouselItem[];
  cta?: CtaButtonProps | null;
  ctaPosition?: Omit<Position, 'left' | 'right'>;
  richText?: Document | unknown;
}
/**This code snippet defines a functional component called TimelineCarousel.
 * It receives props of type TimelineCarouselProps, sets up some variables based on the props,
 * handles background color styling, renders a container with heading, subheading, text, and
 * call-to-action button based on the props, maps through items to render carousel slides with
 * images and content, and optionally displays rich text content at the end. */
const TimelineCarousel = (props: TimelineCarouselProps) => {
  const sliderReference = useRef<HTMLDivElement | null>(null);
  const {
    items,
    heading,
    subHeading,
    alignment,
    backgroundColor,
    headingValue,
    text,
    ctaPosition,
    cta,
    richText
  } = props || {};

  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  const ContainerCtaButton = useCallback(() => {
    if (cta) {
      return (
        <div
          className={` md:text-${alignment} ${ctaPosition === 'bottom' && 'mt-6'}`}
        >
          <CtaButton {...cta} className="ba-text-primary" />
        </div>
      );
    }
    return null;
  }, [cta, alignment, ctaPosition]);

  return (
    <>
      <div className={`py-10 sm:py-12 md:py-14 overflow-hidden ${bg}`}>
        <Container
          fixed
          className="relative mx-auto"
          aria-label="timeline-carousel"
        >
          <div className="w-full mx-auto grid-cols-12 grid">
            <div
              className={`text-${alignment} col-span-12 flex flex-col gap-6
            ${alignment == 'center' ? 'lg:col-start-3 lg:col-span-8' : ''}`}
            >
              {heading && (
                <Heading type={headingValue} className={` text-primary-black `}>
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <h5
                  className="t-18-21 font-frutiger set-frutiger font-bold text-primary-black"
                  aria-label="sub-heading-label"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </h5>
              )}
              {text && (
                <div className="">
                  <p
                    className="t-16-19 mx-auto font-normal color-ba-gray-900"
                    aria-label="text-label"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                </div>
              )}
              {ctaPosition === 'top' && <ContainerCtaButton />}
            </div>
          </div>

          <div
            className="ba-slider-wrapper mt-10  lg:mt-14"
            ref={sliderReference}
          >
            <CarouselWrapper cssClass="overflow-carousel">
              <Carousel {...timelineProperties}>
                {items?.map((data, index: number) => {
                  const image = data?.image;
                  return (
                    <CarouselSlide key={index}>
                      <Stack
                        className="ba-hover-expand-card relative flex justify-end overflow-hidden rounded-md"
                        direction="column"
                        spacing={2}
                      >
                        <figure className="ba-hover-expand-card-pic h-full w-full">
                          {image && (
                            <CmsImage
                              {...image}
                              className="h-full w-full object-cover"
                              altText="background"
                              fill
                              style={{
                                width: '100%',
                                height: '100%'
                              }}
                            />
                          )}
                        </figure>
                        <div className="ba-hover-expand-card-content gradient-dark inline-flex flex-col justify-between">
                          {data?.title && (
                            <h5
                              className="t-18-21 placeholder-title font-frutiger set-frutiger !font-bold !text-white line-clamp-5"
                              aria-label="timeline-title"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.title)
                                }}
                              />
                            </h5>
                          )}
                          {data?.heading && (
                            <h5
                              className="t-18-21 placeholder-title font-frutiger set-frutiger !font-bold !text-white line-clamp-5"
                              aria-label="timeline-heading"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.heading)
                                }}
                              />
                            </h5>
                          )}
                        </div>
                        <div className="ba-hover-expand-card-content card-reveal inline-flex flex-col justify-between gap-3 ">
                          {data?.title && (
                            <h5
                              className="t-18-21 placeholder-title font-frutiger set-frutiger !font-bold !text-white line-clamp-14 shrink-0"
                              aria-label="timeline-title-hover"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.title)
                                }}
                              />
                            </h5>
                          )}
                          <div className="inline-flex flex-col gap-2 min-h-0">
                            {data?.heading && (
                              <h5
                                className="t-18-21  font-frutiger set-frutiger !font-bold !text-white line-clamp-14 shrink-0"
                                aria-label="timeline-heading-hover"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(data?.heading)
                                  }}
                                />
                              </h5>
                            )}
                            <div className="ba-hover-expand-card-wrap inline-flex flex-col items-start  min-h-0 ">
                              {typeof data?.text === 'string' && data?.text && (
                                <p
                                  className="t-16-17  !text-white line-clamp-10  overflow-y-auto custom-scrollbar transparent-scroll scroll-pr-1 "
                                  aria-label="timeline-text-hover"
                                  data-lenis-prevent
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: MarkDown(data?.text)
                                    }}
                                  />
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </Stack>
                    </CarouselSlide>
                  );
                })}
              </Carousel>
            </CarouselWrapper>
            {ctaPosition === 'bottom' && <ContainerCtaButton />}
          </div>

          {(richText as Document) && (
            <div
              className={`mx-auto mt-8 w-full text-${alignment}`}
              aria-label="rich-text-container"
            >
              <CmsRichText
                value={richText as Document}
                className="t-16-17"
                font="font-inter"
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default TimelineCarousel;
