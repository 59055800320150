import { CmsImage, getWrapperProps } from '@bayada/shared/ui-components';
import { ICmsImage } from '@bayada/interfaces';
import { CmsRichText } from '../cms-rich-text/cms-rich-text';
import { WrapperContainer } from '@bayada/shared/ui-components';

export type QuoteProps = {
  id?: string;
  author: string;
  designation?: string;
  text: string;
  image?: ICmsImage | null;
  sysId?: string;
  isGrouped?: boolean;
  testimonial?: boolean;
  internalName?: string;
};
/**
 * Function: Quote
 * @param {QuoteProps} props
 * @returns
 * This code defines a flexible and reusable React component for rendering styled quote blocks,
 * Which can include images, text, and additional information like author and designation.
 * It allows for customization through various props like isGrouped and testimonial.
 * image based on the value of isGrouped.
 * It conditionally renders a WrapperContainer component with specified wrapper and class names.
 * Within the WrapperContainer, it renders a structured HTML for the quote block.
 * It conditionally renders the image using CmsImage component if an image prop is provided.
 * It renders the text content of the quote using CmsRichText component.
 * It renders the author and designation if provided.
 * It applies conditional styling based on the isGrouped and testimonial props.
 */

export function Quote(props: QuoteProps) {
  const {
    author,
    designation,
    text,
    image,
    isGrouped = false,
    testimonial = false
  } = props || {};

  let imageStyle = '';
  if (isGrouped) {
    imageStyle =
      'review-card-img mb-8 flex justify-center sm:justify-end absolute';
  }

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  return (
    <WrapperContainer
      wrapper={wrapperContainer}
      className="relative mx-auto py-10 sm:py-12 md:py-14"
    >
      <div
        className="flex flex-col items-center h-full"
        aria-label="quote-block"
      >
        <div
          className={`${isGrouped && `review-card-wrap h-full bg-white rounded-md img-expansion`} ${testimonial && `pt-10 pb-9`} relative flex flex-col`}
          aria-label="quote-container"
        >
          {image && (
            <div className={`${imageStyle} ${isGrouped && `w-full`}`}>
              <figure className="rounded-full overflow-hidden">
                <CmsImage
                  {...image}
                  width={60}
                  height={60}
                  className={`${isGrouped && `rounded-full overflow-hidden`}`}
                />
              </figure>
            </div>
          )}
          <div
            className={`flex flex-col ${isGrouped && `review-card-content h-full justify-between`}`}
          >
            {text && (
              <div
                className={`${designation || author ? 'mb-10' : 'mb-0'}`}
                aria-label="quote-rich-text"
              >
                <CmsRichText
                  value={text}
                  className={`${testimonial && `t-22-28 font-bold leading-normal`}`}
                />
              </div>
            )}
            <div className={`${testimonial && `mt-4`} flex flex-col`}>
              {author && (
                <p
                  className={`t-16-17 text-primary-black font-inter font-semibold ${designation ? 'mb-2' : 'mb-0'}`}
                  aria-label="quote-author"
                >
                  {author}
                </p>
              )}
              {designation && (
                <p
                  className="t-13 font-inter font-normal color-ba-gray-425"
                  aria-label="quote-designation"
                >
                  {designation}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </WrapperContainer>
  );
}

export default Quote;
