'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button } from '@mui/material';
import { useState } from 'react';
import { GoogleMapContextProvider } from '../../context/google-map-context-provider';
import { PlacesAutocomplete } from '../places-auto-complete/places-auto-complete';
import {
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { useAppContext } from 'apps/bayada/context/app-context';
import { useRouter } from 'next/navigation';
import {
  goToFindOffice,
  isAutoCompleteFieldValid
} from 'apps/bayada/utils/helper';

export interface RequestServiceProps {
  internalName: string;
  requestServiceLabel: string;
  requestServiceHeadingValue: string;
  searchLabel: string;
  buttonText: string;
  service: any;
  searchInputAriaLabel: string;
}

/**
 * The RequestService component renders a form that allows the user to enter
 * a location and request a service. It also renders a button that navigates
 * the user to a page that lists offices that provide the selected service.
 */
export function RequestService(props: RequestServiceProps) {
  const { requestServiceLabel, searchLabel, buttonText, service } = props || {};
  const [location, setLocation] = useState<string | undefined>();
  const router = useRouter();
  const [isAutocompleteValid, setIsAutocompleteValid] =
    useState<boolean>(false);
  const [enteredLoc, setEnteredLoc] = useState<string | undefined | null>(null);

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);
  const { context } = useAppContext();
  const { userAgent } = context || {};

  const onLocationSelected = (
    place: google.maps.places.PlaceResult | null | undefined
  ) => {
    if (place?.formatted_address) {
      setIsAutocompleteValid(true);
      setLocation(place?.formatted_address);
      setEnteredLoc(null);
    }
  };

  const handleGetLocation = (loc: any) => {
    setEnteredLoc(loc?.formatted_address);
    setIsAutocompleteValid(true);
    setLocation(loc?.formatted_address);
  };

  const handleClick = () => {
    const response = goToFindOffice(enteredLoc, location, service?.fullName);
    response?.then(
      (url) => {
        if (url && typeof url === 'string') {
          if (userAgent === 'Firefox') {
            window.location.href = url;
          } else {
            router.push(url);
          }
        }
      },
      (err) => {
        console.log(err, 'Error fetching location...');
      }
    );
  };

  const handleAutocompleteChnge = (evnt: any) => {
    setIsAutocompleteValid(false);

    setLocation(undefined);
    setEnteredLoc(evnt?.target?.value);
    const isValid = isAutoCompleteFieldValid(evnt?.target?.value);
    setIsAutocompleteValid(isValid);
  };

  return (
    <div className="w-full flex flex-col">
      <WrapperContainer
        wrapper={wrapperContainer}
        className="relative mx-auto py-10 sm:py-12 md:py-14"
      >
        <div className={'grid gap-6 content-start'}>
          <div
            className="grid gap-4 content-start w-full lg:w-1/2"
            aria-label="request-service-label"
          >
            {requestServiceLabel && (
              <h3 className="t-22-28 font-semibold font-frutiger">
                {requestServiceLabel}
              </h3>
            )}
            <div>
              <GoogleMapContextProvider>
                <PlacesAutocomplete
                  label={searchLabel}
                  onSelect={onLocationSelected}
                  icon={true}
                  id={'requestService'}
                  hasInitialLocation={true}
                  handleAutocompleteChange={handleAutocompleteChnge}
                  getLocation={(place) => handleGetLocation(place)}
                />
              </GoogleMapContextProvider>
            </div>
          </div>
          <div className="grid sm:mr-auto">
            <Button
              variant="contained"
              color="primary"
              disableFocusRipple
              className="mb-6 w-full sm:w-auto sm:mr-auto"
              onClick={handleClick}
              disableRipple
              disableTouchRipple
              disabled={!isAutocompleteValid}
            >
              <span className="t-15 font-frutiger font-medium leading-snug">
                {buttonText}
              </span>
            </Button>
          </div>
        </div>
      </WrapperContainer>
    </div>
  );
}

export default RequestService;
