'use client';
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CmsVideoProps,
  CtaButton,
  CtaButtonProps,
  Heading,
  Icon
} from '@bayada/shared/ui-components';
import { Container, Grid } from '@mui/material';
import CtaPopup from '../cta-modal-popup/cta-modal-popup';
import React, { useState } from 'react';
import './banner.scss';
import { MarkDown } from '@bayada/shared/ui-components';
import dynamic from 'next/dynamic';
/* eslint-disable-next-line */
export interface HomeHeroBannerProps {
  internalName?: string;
  heading: string;
  headingValue: 'h1' | 'h2' | 'h3';
  subHeading: string;
  text: string;
  backgroundVideo: any;
  popupLinks: any;
  cta: CtaButtonProps[] | null;
  sys?: any;
}

/* This code snippet defines a functional component named `HomeHeroBanner` in TypeScript React. The
component takes in props of type `HomeHeroBannerProps` which includes various properties like
`heading`, `subHeading`, `text`, `backgroundVideo`, `cta`, `popupLinks`, and `sys`. */
/**
 * This component renders the template tags based on configuration set by the parent component that invokes it.
 * @param props
 * @returns template of Hero banner for Home page
 */

const CmsVideo = dynamic<CmsVideoProps>(
  () =>
    import('@bayada/shared/ui-components').then((module) => module.CmsVideo),
  {
    loading: () => <p>Loading...</p>
  }
);

export function HomeHeroBanner(props: HomeHeroBannerProps) {
  const {
    headingValue,
    heading,
    subHeading,
    text,
    backgroundVideo,
    cta,
    popupLinks,
    sys
  } = props || {};

  const [showModal, setShowModal] = useState(false);
  const [pauseButton, setPauseButton] = useState(false);
  const sysId = sys?.id;
  const closePopup = () => {
    setShowModal(false);
  };

  const openPopup = () => {
    setShowModal(true);
  };
  const videoPlay = () => {
    setPauseButton(!pauseButton);
  };
  return (
    <div className="home-hero hero-banner flex h-full items-end md:items-center">
      <div
        className="hero-background relative overflow-hidden pointer-events-none left-gradient bg-ba-primary-black"
        role="img"
        aria-label="background"
      >
        {backgroundVideo?.videoType ? (
          <CmsVideo
            {...backgroundVideo}
            muted
            loop
            hideControl
            width="100%"
            height="100%"
            pauseButton={pauseButton}
            volume={0}
            controls={0}
            autoPlay
          />
        ) : null}
      </div>
      <Container
        fixed
        className={'hero-banner-content py-[3.75rem]'}
        role="group"
        aria-label="Hero Banner Elements"
      >
        <Grid container spacing={2}>
          <Grid item base={12} md={8} lg={6} xl={6}>
            {heading && (
              <Heading
                type={headingValue}
                isOnHomeBanner={true}
                id={`banner-heading-${sysId}`}
                ariaLevel="1"
                className="mb-6 text-white md:mt-6 text-shadow origin-left fhd:scale-125 kkk:scale-150"
              >
                <span dangerouslySetInnerHTML={{ __html: MarkDown(heading) }} />
              </Heading>
            )}
            {subHeading && (
              <h2
                id={`banner-subheading-${sysId}`}
                className="t-18-21 mb-3 text-white font-frutiger set-frutiger text-shadow origin-left fhd:scale-125 kkk:scale-150"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                />
              </h2>
            )}
            {text && (
              <p
                id="banner-text"
                className="t-16-17 mb-6 !font-frutiger text-white text-shadow origin-left fhd:scale-125 kkk:scale-150"
              >
                <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
              </p>
            )}
            <div className="cta-holder flex gap-4 lg:gap-6  flex-col xs:flex-row xs:items-center xs:flex-wrap empty:hidden">
              {cta &&
                cta?.length &&
                cta?.map((item: any, index: number) => (
                  <div
                    key={index}
                    className="banner-cta-wrapper cta-wrapper mt-4 w-full xs:w-auto"
                  >
                    <CtaButton
                      {...(item as CtaButtonProps)}
                      textClass="font-frutiger t-15"
                      onClick={
                        (item as CtaButtonProps)?.url ? undefined : openPopup
                      }
                      className="mx-auto w-full"
                      sx={
                        item.buttonType === 'outlined'
                          ? {
                              border: '1px solid var(--white)',
                              backgroundColor: 'rgba(var(--primary-black),0.1)',
                              color: 'var(--white)',
                              '&:hover': {
                                backgroundColor: 'var(--white)',
                                color: 'var(--ba-primary-black)'
                              }
                            }
                          : {}
                      }
                    />
                  </div>
                ))}
            </div>
          </Grid>
        </Grid>
      </Container>
      <button
        onClick={() => videoPlay()}
        title={pauseButton ? 'Play video' : 'Pause video'}
        aria-label={pauseButton ? 'Play video' : 'Pause video'}
        className={`absolute bottom-0 left-0 z-10 text-white px-2 py-2 ml-0 lg:ml-3 transition-opacity delay-75 duration-300 ease-in-out will-change-[opacity] opacity-80 hover:opacity-100 focus:opacity-100`}
      >
        {pauseButton ? (
          <Icon iconName="playButton" className="svg-icon icon-20" />
        ) : (
          <Icon iconName="pauseVideo" className="svg-icon icon-20" />
        )}
      </button>
      <CtaPopup
        open={showModal}
        handleClose={closePopup}
        popupLinks={popupLinks}
      />
    </div>
  );
}

export default HomeHeroBanner;
