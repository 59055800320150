/* eslint-disable @typescript-eslint/no-explicit-any */
import { Container, Link } from '@mui/material';
import NavigationLink, {
  NavigationLinkProps
} from '../navigation-link/navigation-link';
import { CmsImage } from '@bayada/shared/ui-components';
import '../navigation-header/navigation-header.scss';
import MenuListComposition from '../menu-list/menu-list';
import { useInView } from 'react-intersection-observer';
import { homePageUrl } from 'apps/bayada/constants';
import { useAppContext } from 'apps/bayada/context/app-context';
import { useScrollDirection } from '@bayada/hooks';

type PageLogo = {
  altText?: string;
  ariaLabel?: string;
  internalName?: string;
  icon?: any;
  iconPosition?: string;
  imageLarge?: any;
};

export type PrimaryNavigationProps = {
  homePageLogo?: PageLogo;
  commonLogo?: PageLogo;
  menuItems?: NavigationLinkProps[];
  imageLink?: string;
  pathname?: string;
  drawerItem?: boolean;
};
/**
 * This code defines a React functional component called PrimaryNavigation that renders a
 * navigation bar with a logo, menu items, and links.
 * @property {string} altText - The `altText` property is used to provide alternative text for an
 * image. This text is displayed when the image cannot be loaded or when a screen reader is used to
 * describe the image to visually impaired users. It should be descriptive and convey the purpose or
 * content of the image.
 * @property {string} ariaLabel - The `ariaLabel` property is used to provide a label or description
 * for an element that is accessible to screen readers and other assistive technologies. It helps to
 * improve the accessibility of the website by providing additional context or information about the
 * element for users who may not be able to see it visually.
 * @property {string} internalName - The `internalName` property in the `PageLogo` type is used to
 * specify the internal name of the logo. It seems to be a string type property that can be used to
 * uniquely identify or reference the logo within the application. This property might be used for
 * internal purposes such as identifying the logo
 * @property {any} icon - The `icon` property in the `PageLogo` type is defined as `any`, which means
 * it can accept any type of value. This can be useful when you are unsure of the specific type of icon
 * that will be used and want to allow flexibility in the data structure. However, using `
 * @property {string} iconPosition - The `iconPosition` property is a string type property in the
 * `PageLogo` type. It is used to specify the position of an icon within the page logo. This property
 * allows you to control whether the icon should be displayed on the left, right, top, or bottom of the
 * logo image
 * @property {any} imageLarge - The `imageLarge` property in the `PageLogo` type is used to store an
 * image object that represents a logo or icon. It can have the following properties:
 */

export const PrimaryNavigation: React.FC<PrimaryNavigationProps> = (
  props: PrimaryNavigationProps
) => {
  const {
    homePageLogo,
    commonLogo,
    drawerItem,
    imageLink,
    menuItems,
    pathname
  } = props || {};
  const { scrollY } = useScrollDirection();
  const { context } = useAppContext();
  const { userAgent } = context || {};
  const pagesWithTransparentNav = [homePageUrl];
  const isTransparentNav = pagesWithTransparentNav?.includes(
    pathname ? pathname : ''
  );
  const [logoRef, inView] = useInView({
    threshold: 0.1,
    triggerOnce: true
  });
  const logo =
    isTransparentNav && scrollY <= 150
      ? homePageLogo?.imageLarge?.file?.url
      : commonLogo?.imageLarge?.file?.url;

  return (
    <div className="primary-navbar hidden items-center py-3 lg:flex">
      <Container
        fixed
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <div
          role="link"
          className={`primary-nav-logo overflow-hidden ${inView ? 'scale-100' : 'scale-90'} transition-transform will-change-transform delay-100 duration-300 ease-in-out`}
          aria-label="image-container"
          ref={logoRef}
        >
          <Link
            tabIndex={0}
            href={imageLink ?? homePageUrl}
            className="nav-link"
            aria-live="polite"
            onClick={(e) => {
              if (userAgent === 'Firefox') {
                e?.preventDefault();
                window.location.href = imageLink ?? homePageUrl;
              }
            }}
          >
            {logo && (
              <CmsImage
                imagelarge={logo}
                altText={
                  isTransparentNav ? homePageLogo?.altText : commonLogo?.altText
                }
                ariaLabel={
                  isTransparentNav
                    ? homePageLogo?.ariaLabel
                    : commonLogo?.ariaLabel
                }
                width={190}
                height={44}
                className="object-contain object-left"
                priority={true}
              />
            )}
          </Link>
        </div>

        <div className="navigation-group">
          <ul className="navigation-list item-center inline-flex gap-4">
            {menuItems?.map((item: NavigationLinkProps) => {
              return (
                item?.text && (
                  <li key={item?.text} className="nav-item">
                    {item?.dropdown && item?.dropdown?.length > 0 ? (
                      <MenuListComposition
                        item={item}
                        drawerItem={drawerItem}
                        triggerClassName="t-13 leading-snug"
                      />
                    ) : (
                      <span className="nav-link t-13 leading-snug h-full font-bold">
                        <NavigationLink
                          isDrawerDropdownLink={true}
                          {...item}
                          className="px-2 py-1 cursor-pointer"
                          textClass="!font-frutiger set-frutiger"
                        />
                      </span>
                    )}
                  </li>
                )
              );
            })}
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default PrimaryNavigation;
