/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Divider, Tooltip } from '@mui/material';
import { OfficePageProps, SpecialityProps } from './office-page';
import { Icon } from '@bayada/shared/ui-components';
import { handleDirections } from './office-page-helper';
import { CmsRichText } from '../cms-rich-text/cms-rich-text';
import { CmsHubSpotForm } from '../hub-spot-form/cms-hub-spot-form';
import WorkplaceCertificate from './workplace-certificate';
import { replaceWithOfficeData } from 'apps/bayada/services/service-helper';
import { useCallback } from 'react';
import { OfficeModel } from 'apps/bayada/app/find-an-office/office-data-parser';
import { useAppContext } from 'apps/bayada/context/app-context';
import { useRouter } from 'next/navigation';
import { trimCommasAndSpaces } from 'apps/bayada/utils/helper';
import {
  constructOfiicePageRoute,
  handlePhoneClick
} from 'apps/bayada/app/find-an-office/office-components/offices-list';

export interface OfficeSectionProps {
  office: OfficeModel;
  officePage?: OfficePageProps | null;
  servicesOffered?: SpecialityProps[];
}

export const OfficeDetailsSection = (props: OfficeSectionProps) => {
  const { office, officePage, servicesOffered } = props || {};
  const router = useRouter();
  const { context } = useAppContext();
  const { userAgent } = context || {};

  /**
   * Handles click event when a service specialty is clicked.
   * Navigates to the corresponding service page.
   *
   * @param {SpecialityProps} spec - The specialty object representing the clicked service.
   */
  const handleServiceClick = (spec: SpecialityProps) => {
    if (!spec?.isCurrentService && window) {
      const route = constructOfiicePageRoute(office, spec?.fullName);
      if (userAgent === 'Firefox') {
        window.location.href = route;
      } else {
        router.push(route);
      }
    }
  };

  /**
   * Replaces placeholders in rich text content with office mailing address city and state.
   *
   * @param {any} content - The rich text content to be processed.
   */
  const replaceRichTextData = useCallback(
    (content: any) => {
      content?.forEach(function (cont: any) {
        if (cont?.value && office) {
          cont.value = replaceWithOfficeData(cont?.value, office);
        }
        if (cont?.content) {
          replaceRichTextData(cont?.content);
        }
      });
    },
    [office]
  );

  if (props) {
    const { aboutContent, aboutBayadaText, contactUsContent } =
      officePage || {};
    [aboutContent, aboutBayadaText, contactUsContent].forEach((content) =>
      replaceRichTextData(content?.content)
    );
  }

  return (
    <div className="office-page relative">
      <div className="office-section flex flex-col gap-8 min-w-0 lg:col-span-6">
        <div itemScope itemType="https://schema.org/LocalBusiness">
          <h2
            itemProp="name"
            className="t-22-28 font-bold mb-2 font-frutiger color-ba-primary-black"
          >
            {office?.name}
          </h2>
          {office?.licenseNumber && (
            <p className="t-16-17 mt-1 font-normal color-ba-primary-black ">{` License: ${office?.licenseNumber}`}</p>
          )}
          <div
            itemProp="address"
            itemScope
            itemType="https://schema.org/PostalAddress"
          >
            {office?.mailingAddress1 && (
              <span
                itemProp="streetAddress"
                className="t-14-15 font-normal mb-2 color-ba-gray-900"
              >
                {trimCommasAndSpaces(office?.mailingAddress1) + ','}{' '}
              </span>
            )}
            {office?.mailingAddress2 && (
              <span
                itemProp="streetAddress"
                className="t-14-15 font-normal mb-2 color-ba-gray-900"
              >
                {trimCommasAndSpaces(office?.mailingAddress2) + ','}{' '}
              </span>
            )}
            {office?.mailingAddressCity && (
              <span
                itemProp="addressLocality"
                className="t-14-15 font-normal mb-2 color-ba-gray-900"
              >
                {trimCommasAndSpaces(office?.mailingAddressCity) + ','}{' '}
              </span>
            )}
            <span
              itemProp="addressRegion"
              className="t-14-15 font-normal mb-2 color-ba-gray-900"
            >
              {trimCommasAndSpaces(office?.mailingAddressState) + ','}{' '}
            </span>
            <span
              itemProp="postalCode"
              className="t-14-15 font-normal mb-2 color-ba-gray-900"
            >
              {office?.mailingAddressZipCode}
            </span>
          </div>
          <div className="flex flex-wrap items-center">
            <a
              className="t-13 inline-flex gap-1 mt-2"
              title="Get directions"
              aria-label="Get directions"
              tabIndex={0}
              style={
                officePage?.directorLabel || office?.directorName
                  ? { marginRight: 12 }
                  : { marginRight: 0 }
              }
            >
              <Icon
                iconName="locationPin"
                className="svg-icon icon-16 flex items-center justify-center color-ba-gray-900"
              ></Icon>
              <span
                className={`${office?.isJV ? `color-ba-blue-600` : `text-primary-shade`} underline-offset-2 underline cursor-pointer`}
                onClick={() => handleDirections(office)}
                aria-label="direction-label"
              >
                {officePage?.getDirectionsLabel}
              </span>
            </a>
            {(officePage?.directorLabel || office?.directorName) && (
              <div
                itemScope
                itemType="https://schema.org/Thing"
                aria-label="director-container"
                className="mt-2"
              >
                <p
                  itemProp="name"
                  className="t-14-15 font-normal mb-0 color-ba-gray-900"
                >
                  {officePage?.directorLabel}
                  {office?.directorName && `: `}
                  {office?.directorName}
                </p>
              </div>
            )}
          </div>
        </div>

        <Divider orientation="horizontal" />

        <div
          itemProp="OpeningHours"
          className="grid md:grid-cols-3 grid-cols-2 t-13 gap-1 color-ba-gray-900"
        >
          {officePage?.officeTimings?.map((day: string, index: number) => (
            <div key={index} className={`col-span-1 flex items-center gap-3 }`}>
              <span
                className="color-ba-gray-900"
                aria-label="office-timings-container"
              >
                {day}
              </span>
            </div>
          ))}
        </div>
      </div>
      {/* form */}
      <div
        className={`form-section ${office?.isJV && 'blue-theme'} flex w-full flex-col min-w-0 lg:col-span-5 lg:col-start-8 lg:mt-0 mt-10`}
      >
        <div className="form-sticky flex flex-col gap-6 lg:gap-6">
          <h5 className="t-18-21 font-frutiger font-bold">
            {officePage?.formHeader}
          </h5>
          {office?.phone && (
            <div className="flex justify-between flex-col sm:flex-row gap-6">
              <button
                itemProp="Contact Point"
                itemScope
                itemType="https://schema.org/ContactPoint"
                className={`t-22-28 p-4 pr-8 rounded-lg font-frutiger flex gap-2 items-center justify-center  ${office?.isJV ? `bg-ba-blue-600` : `bg-ba-primary-red`} font-bold cursor-pointer text-white`}
                onClick={() => handlePhoneClick(office?.phone)}
                aria-label="phone-container"
              >
                <Icon
                  iconName="call"
                  className="svg-icon icon-24 flex items-center justify-center"
                ></Icon>
                <span
                  className="font-frutiger t-22-28"
                  aria-label="phone-text-container"
                  itemProp="telephone"
                >
                  {office?.phone
                    ?.replace(/\D/g, '')
                    ?.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')}
                </span>
              </button>
            </div>
          )}
          {officePage?.contactUsContent && (
            <CmsRichText
              value={officePage?.contactUsContent as unknown as Document}
              isJv={office?.isJV}
            />
          )}
          {officePage?.form && (
            <CmsHubSpotForm
              {...officePage?.form}
              prefillFields={[
                {
                  fieldName: 'officenumber',
                  value: JSON.stringify(office?.number),
                  isHidden: true
                },
                {
                  fieldName: 'practice_area',
                  value: JSON.stringify(office?.practice),
                  isHidden: true
                }
              ]}
            />
          )}
        </div>
      </div>
      <div className=" about-us-section flex flex-col gap-8  min-w-0  lg:col-span-6 lg:col-start-1 mt-10 ">
        <h2
          className="t-22-28 font-bold mb-0 font-frutiger color-ba-primary-black"
          aria-label="about-us-label"
        >
          {officePage?.aboutUsLabel}
        </h2>

        <div aria-label="expanded-content">
          <div aria-label="about-content" itemProp="description">
            {officePage?.aboutContent && (
              <CmsRichText
                value={officePage?.aboutContent as unknown as Document}
                isJv={office?.isJV}
              />
            )}
          </div>
          {officePage?.accreditationList && (
            <WorkplaceCertificate images={officePage?.accreditationList} />
          )}
          {office?.serviceAreas && (
            <div className="mb-6 mt-12">
              <h5
                className="t-16-17 mb-4 font-frutiger font-bold color-ba-primary-black"
                aria-label="service-arealabel-container"
              >
                {officePage?.serviceAreasLabel}
              </h5>

              <p
                className="t-16-17 font-frutiger color-ba-gray-900"
                aria-label="service-areacountry-container"
              >
                {office?.serviceAreas}
              </p>
            </div>
          )}
          {/* Services Offered */}
          {!office?.isJV && (
            <div className="flex flex-col gap-4">
              <h5
                className="t-16-17 font-frutiger font-bold color-ba-primary-black"
                aria-label="services-offered-label"
              >
                {officePage?.servicesOfferedLabel}
              </h5>
              <div className="flex gap-1 flex-wrap">
                {servicesOffered?.map(
                  (speciality: SpecialityProps, index: number) => {
                    return (
                      <div
                        key={index}
                        className="flex items-center gap-1 color-ba-primary-black  t-15  "
                      >
                        {!speciality?.isCurrentService ? (
                          <Tooltip
                            title={`Visit the office page for ${speciality?.fullName}`}
                            placement="top-end"
                            arrow
                            slotProps={{
                              popper: {
                                modifiers: [
                                  {
                                    name: 'offset',
                                    options: {
                                      offset: [0, -3]
                                    }
                                  }
                                ]
                              }
                            }}
                            aria-label="tool-tip-container"
                          >
                            <div className="flex gap-1">
                              <a
                                className="t-16-17 font-frutiger text-primary-shade underline underline-offset-2 outline-offset-2 cursor-pointer"
                                aria-label={speciality?.fullName}
                                tabIndex={0}
                                onClick={() => handleServiceClick(speciality)}
                              >
                                {speciality?.fullName}
                              </a>
                              {index !== servicesOffered?.length - 1 && (
                                <p className="t-16-17 font-frutiger color-ba-gray-900">
                                  {`.`}
                                </p>
                              )}
                            </div>
                          </Tooltip>
                        ) : (
                          <div className="flex gap-1">
                            <p
                              className="t-16-17 font-frutiger color-ba-gray-900"
                              onClick={() => handleServiceClick(speciality)}
                            >
                              {speciality?.fullName}
                            </p>
                            {servicesOffered?.length > 1 &&
                              index !== servicesOffered?.length - 1 && (
                                <p className="t-16-17 font-frutiger color-ba-gray-900">
                                  {`.`}
                                </p>
                              )}
                          </div>
                        )}
                      </div>
                    );
                  }
                )}
              </div>
            </div>
          )}
          <Divider
            orientation="horizontal"
            sx={{
              marginTop: '30px',
              marginBottom: '30px'
            }}
          />
          <div className="my-6">
            <h2
              className="t-22-28 mb-4 font-frutiger font-bold color-ba-primary-black"
              aria-label="about-bayada-label"
            >
              {officePage?.aboutBayadaLabel}
            </h2>
            <div aria-label="about-bayada-text">
              {officePage?.aboutBayadaText && (
                <CmsRichText
                  value={officePage?.aboutBayadaText as unknown as Document}
                  isJv={office?.isJV}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
