/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { usePathname, useRouter } from 'next/navigation';
import {
  useEffect,
  useState,
  useRef,
  useMemo,
  useCallback,
  Suspense
} from 'react';
import PrimaryNavigation, {
  PrimaryNavigationProps
} from '../primary-navigation/primary-navigation';
import './navigation-header.scss';
import TopNavigation, {
  TopNavigationProps
} from '../navigation-top/top-navigation';
import NavigationDrawer from './navigation-drawer/navigation-drawer';
import BreadCrumbs from '../breadcrumbs/breadcrumbs';
import {
  useContentfulInspectorMode,
  useContentfulLiveUpdates
} from '@contentful/live-preview/react';
import { parseHeaderNavigatonData } from 'apps/bayada/utils/component-data-parser';
import { Entry, EntrySkeletonType } from 'contentful';
import { useAppContext } from 'apps/bayada/context/app-context';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { serviceDataProps } from 'apps/bayada/app/find-an-office/find-an-office-page';
import { convertObjectToArray } from 'apps/bayada/utils/helper';
import uniqBy from 'lodash/uniqBy';
import { useDeviceSize, useScrollDirection } from '@bayada/hooks';
import { breakpoints } from '@bayada/assets';
import { homePageUrl } from 'apps/bayada/constants';

export type CmsHeaderProps = {
  data?: Entry<EntrySkeletonType, undefined, string> | undefined | any;
  isDraftMode?: boolean;
  searchButtonVisible?: boolean;
  office?: any;
  serviceList?: serviceDataProps[];
  skipLinkData?: any[];
};

export type NavigationHeaderProps = {
  topNavigation?: TopNavigationProps;
  primaryNavigation?: PrimaryNavigationProps;
};

export function NavigationHeader(props: CmsHeaderProps) {
  const pathname = usePathname();
  const { context } = useAppContext();
  const { userAgent } = context || {};
  const inspectorProperties = useContentfulInspectorMode();
  const [isOpen, setIsOpen] = useState(false);
  const scrollDirection = useScrollDirection();
  const [scrollWatchEnabled, setscrollWatchEnabled] = useState(false);
  const [hamburgerWrap, setHamburgerWrap] = useState<any>('');
  const [state] = useState(props?.data);
  const updatedState = useContentfulLiveUpdates(state);
  const { isProgressbarRequired } = useAppContext();
  const pagesWithTransparentNav = [homePageUrl];
  const isTransparentNav = pagesWithTransparentNav?.includes(pathname);
  const transparentCssClass = isTransparentNav
    ? 'has-transparent-nav'
    : 'has-default-nav';

  const router = useRouter();
  const navHeaderData: NavigationHeaderProps = useMemo(() => {
    return parseHeaderNavigatonData(updatedState);
  }, [updatedState]);
  const [scrollProgress, setScrollProgress] = useState(0);
  const [skipToSectionData, setSkipToSectionData] = useState<any[]>([]);

  const appNavRef = useRef<HTMLDivElement>(null);
  const appRef = useRef<HTMLDivElement>(null);
  const primaryNavRef = useRef<HTMLDivElement>(null);
  // Enable this method on clicking the scroll to section dropdown
  const [isFocused, setIsFocused] = useState(false); // setting tab focus for skip-link
  const [selectedValue, setSelectedValue] = useState(''); // setting skip-link values
  const [open, setOpen] = useState(false); // setting skip-link list close on window scroll

  const handleClose = () => {
    setOpen(false);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };
  const handleBlur = () => {
    setIsFocused(false);
  };

  const buildSkipLinkData = useCallback(() => {
    const skipToSectionInitialData = convertObjectToArray(props?.skipLinkData);
    skipToSectionInitialData?.forEach((item) => {
      if (item && typeof item === 'object') {
        item.customId = String(item?.fields?.internalName)
          ?.trim()
          ?.replace(/\s/g, '-');
      }
    });
    const skipToSectionAllData = skipToSectionInitialData
      ?.map((x) => {
        return {
          id: x?.customId,
          value: x?.fields?.internalName
        };
      })
      ?.filter((item) => item.value);
    const result = uniqBy(skipToSectionAllData, 'id');
    setSkipToSectionData(result);
  }, [props?.skipLinkData]);

  const handleOptionSelect = useCallback((event: any) => {
    const value = event.target.value;
    // window.location.hash = `#${value}`;
    userAgent === 'Firefox'
      ? (window.location.hash = `#${value}`)
      : router?.push(`#${value}`);

    setSelectedValue('');
  }, []);
  // return focus to the button when we transitioned from !open -> open

  const TopNav = useMemo(
    () => (
      <TopNavigation
        {...navHeaderData?.topNavigation}
        searchButtonVisible={props?.data?.searchButtonVisible}
      />
    ),
    [navHeaderData?.topNavigation, props?.data?.searchButtonVisible]
  );
  /**
   *This code snippet contains a function handleResize that adjusts the minimum height of
   *the main app container based on various conditions like the presence of a transparent
   *navigation bar or a hamburger wrap. It ensures that the content section always stretches
   *to the bottom of the page by calculating the appropriate min-height dynamically.
   */
  const [width] = useDeviceSize();
  const handleResize = useCallback(() => {
    let primaryNavRefHeight = 0;
    if (primaryNavRef.current) {
      primaryNavRefHeight = primaryNavRef.current.clientHeight;
    }
    if (appNavRef.current && appRef.current) {
      const appNavHeight = appNavRef.current.clientHeight;
      if (isTransparentNav) {
        if (primaryNavRefHeight) {
          const topNavHeight = appNavHeight - primaryNavRefHeight;
          appRef.current.style.minHeight = `${topNavHeight}px`;
        } else if (hamburgerWrap) {
          const topNavHeight = appNavHeight - hamburgerWrap;
          appRef.current.style.minHeight = `${topNavHeight}px`;
        }
      } else {
        appRef.current.style.minHeight = `${appNavHeight}px`;
      }
    }
    if (width > Number(breakpoints.lg)) {
      setIsOpen(false);
    }
  }, [width, isTransparentNav, hamburgerWrap]);

  /**
   * Handle scroll event on the window
   *
   * If the scrollWatchEnabled state is false and isDraftMode is false and the user has scrolled down,
   * then set the scrollWatchEnabled state to true. This is to prevent
   * the progress bar from being updated when the user is in draft mode.
   *
   * If the isProgressRequired prop is true, then calculate the progress
   * of the page scroll and update the scrollProgress state. The progress
   * is calculated by dividing the page scroll position by the difference
   * between the document height and the window height, then multiplying
   * that value by 100.
   */
  const handleScroll = useCallback(() => {
    if (
      !scrollWatchEnabled &&
      !props?.isDraftMode &&
      (scrollDirection?.scrollY || window?.scrollY) > 0
    ) {
      setscrollWatchEnabled(true);
    }
    if (open) {
      handleClose();
    }

    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const progress = (scrollTop / (documentHeight - windowHeight)) * 100;
    setScrollProgress(progress);
  }, [open, props?.isDraftMode, scrollDirection?.scrollY, scrollWatchEnabled]);

  const drawerOpen = (isNavDrawerOpen?: boolean) => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    buildSkipLinkData();
  }, [buildSkipLinkData]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(handleResize);
    if (appNavRef.current) {
      resizeObserver.observe(appNavRef.current);
    }
    window.addEventListener('scroll', handleScroll);
    handleBlur();
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleResize, handleScroll]);

  if (!updatedState?.topNavigation && !updatedState?.primaryNavigation) {
    return null;
  }

  return (
    <nav className="app-nav" ref={appRef}>
      <Suspense fallback={<div>Navbar Loading...</div>}>
        {skipToSectionData?.length > 0 && (
          <div
            className={`skip-link hidden lg:block ml-2 ${isFocused ? 'focused' : ''}`}
          >
            <FormControl
              sx={{ m: 1, minWidth: 120, backgroundColor: 'var(--white)' }}
              className="skip-links"
            >
              <InputLabel
                htmlFor="select-label-field"
                className="z-[1600] cursor-pointer !pointer-events-none"
              >
                skip-links
              </InputLabel>
              <Select
                value={selectedValue}
                onChange={handleOptionSelect}
                inputProps={{
                  'aria-label': 'Skip to content',
                  id: 'select-label-field'
                }}
                onFocus={handleFocus}
                onBlur={handleBlur}
                open={open}
                onClose={handleClose}
                onOpen={() => setOpen(true)}
                className="skip-link-list"
                MenuProps={{
                  MenuListProps: {
                    'data-lenis-prevent': true
                  } as any
                }}
              >
                {skipToSectionData?.map((item, index) => (
                  <MenuItem
                    key={index}
                    value={item.id}
                    className="skip-link-list-items"
                  >
                    {item.value}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        )}
        <div
          ref={appNavRef}
          className={`app-nav-wrap max-w-dvw top-0 right-0 left-0 w-dvw ${isOpen && '!transform-none'} ${
            scrollDirection?.scrollDirection === 'up' &&
            scrollDirection?.scrollY > 150
              ? ''
              : transparentCssClass
          } ${props?.isDraftMode ? 'absolute' : 'fixed'} top-0`}
          style={{
            transform: `translateY(${
              scrollDirection?.scrollDirection === 'up'
                ? 0
                : scrollWatchEnabled
                  ? isProgressbarRequired
                    ? 'calc((-100% + 5px))'
                    : 'calc((-100%))'
                  : 0
            })`
          }}
        >
          <div
            className="w-full"
            {...inspectorProperties({
              entryId: updatedState?.topNavigation?.sys?.id ?? '',
              fieldId:
                updatedState?.topNavigation?.fields?.internalName?.toString() ??
                ''
            })}
            aria-label="top-nav"
          >
            {TopNav}
          </div>

          <div
            ref={primaryNavRef}
            className="w-full relative z-10"
            {...inspectorProperties({
              entryId: updatedState?.primaryNavigation?.sys?.id ?? '',
              fieldId:
                updatedState?.primaryNavigation?.fields?.internalName?.toString() ??
                ''
            })}
            aria-label="primary-nav"
          >
            <PrimaryNavigation
              // forwardRef={appRef}
              {...navHeaderData?.primaryNavigation}
              pathname={pathname}
            />
          </div>

          {/* Enable with whitepaper/resources/news pages */}
          {isProgressbarRequired && (
            <div
              className="hidden bg-ba-blue-300 lg:block z-50 h-1"
              style={{ width: `${scrollProgress}%` }}
            ></div>
          )}

          <NavigationDrawer
            updatedState={updatedState}
            {...navHeaderData}
            drawerOpen={drawerOpen}
            pathname={pathname}
            setHamburgerWrap={setHamburgerWrap}
          />
          {!isOpen && (
            <BreadCrumbs
              office={props?.office}
              serviceList={props?.serviceList}
            />
          )}
          {/* Enable with whitepaper/resources/news pages  */}
          {isProgressbarRequired && (
            <div
              className="bg-ba-blue-300 z-50 h-2 lg:hidden"
              style={{ width: `${scrollProgress}%` }}
            ></div>
          )}
        </div>
      </Suspense>
    </nav>
  );
}

export default NavigationHeader;
