import { directionUrl } from 'apps/bayada/constants';

/**
 * Opens the default maps app to provide directions from the origin to the specified destination.
 * @param {string} origin - The origin address or coordinates.
 * @param {string} lat - The latitude of the destination.
 * @param {string} lng - The longitude of the destination.
 */
export const goToDirections = (origin: string, lat: string, lng: string) => {
  const directionsUrl = directionUrl
    ?.replace('{origin}', origin)
    ?.replace('{destinationLat}', lat)
    ?.replace('{destinationLng}', lng);
  if (window) {
    window?.open(directionsUrl);
  }
};

/**
 * Handles the action to get directions to a specific office location.
 * @param {Object} office - The office location details.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const handleDirections = (office?: any) => {
  const destination = {
    lat: office?.mailingAddressLatitude,
    lng: office?.mailingAddressLongitude
  };
  const origin = '';
  goToDirections(origin, destination?.lat, destination?.lng);
};

export const toTitleCase = (str: string) => {
  return str.toLowerCase().replace(/(?:^|\s)\w/g, (match) => {
    return match.toUpperCase();
  });
};
