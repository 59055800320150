import { SwiperProps } from 'swiper/react';

export const carouselPropertiesCommon: SwiperProps = {
  keyboard: { enabled: true },
  scrollbar: true,
  navigation: false,
  pagination: false,
  grabCursor: true,
  loop: false,
  centeredSlides: false,
  spaceBetween: 24
};

export const servicesCarouselProps: SwiperProps = {
  ...carouselPropertiesCommon,
  slidesPerView: 4,
  slidesPerGroupSkip: 3,
  className: 'services-carousel',
  scrollbar: { draggable: true },
  allowTouchMove: true,

  breakpoints: {
    1400: {
      slidesPerView: 4.2,
      slidesPerGroup: 1
    },
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 1
    },
    991: {
      slidesPerView: 2.5,
      slidesPerGroup: 1
    },
    769: {
      slidesPerView: 2.5,
      slidesPerGroup: 1
    },
    575: {
      slidesPerView: 2,
      slidesPerGroup: 1
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1
    }
  }
};

export const articlesCarouselProps: SwiperProps = {
  ...carouselPropertiesCommon,
  slidesPerView: 4,
  slidesPerGroupSkip: 0,
  className: 'articles-carousel',
  spaceBetween: 24,
  scrollbar: { draggable: true },
  allowTouchMove: true,
  breakpoints: {
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    769: {
      slidesPerView: 2.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    575: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 8
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 8
    }
  }
};

export const quickHelpCarouselProperties: SwiperProps = {
  ...carouselPropertiesCommon,
  slidesPerView: 4,
  slidesPerGroupSkip: 0,
  className: 'quick-help-carousel',
  scrollbar: { draggable: true },
  allowTouchMove: true,
  breakpoints: {
    1200: {
      slidesPerView: 4,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    769: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    575: {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 16
    }
  }
};

export const serviceDetailCarouselProperties: SwiperProps = {
  ...carouselPropertiesCommon,
  slidesPerView: 2,
  slidesPerGroupSkip: 1,
  className: 'service-detail-carousel',
  scrollbar: { draggable: true },
  allowTouchMove: true,
  breakpoints: {
    1200: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    769: {
      slidesPerView: 2.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    575: {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 8
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 8
    }
  }
};

export const timelineProperties: SwiperProps = {
  slidesPerView: 4.2,
  centeredSlides: false,
  slidesPerGroupSkip: 3,
  centerInsufficientSlides: true,
  grabCursor: true,
  keyboard: {
    enabled: true
  },
  scrollbar: { draggable: true },
  allowTouchMove: true,
  navigation: false,
  pagination: false,
  className: 'timeline-carousal',
  breakpoints: {
    1200: {
      slidesPerView: 4.2,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 2.5,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    769: {
      slidesPerView: 2.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    575: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 8
    },
    0: {
      slidesPerView: 1.2,
      slidesPerGroup: 1,
      spaceBetween: 8
    }
  }
};

export const testimonialProperties: SwiperProps = {
  slidesPerView: 3,
  // centeredSlides: false,
  slidesPerGroupSkip: 0,
  // centerInsufficientSlides: true,
  grabCursor: true,
  autoHeight: false,
  keyboard: {
    enabled: true
  },
  scrollbar: { draggable: true },
  allowTouchMove: true,
  breakpoints: {
    1200: {
      slidesPerView: 3,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    991: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 24
    },
    769: {
      slidesPerView: 2,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    575: {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    },
    0: {
      slidesPerView: 1.5,
      slidesPerGroup: 1,
      spaceBetween: 16
    }
  },
  navigation: false,
  pagination: false,
  className: 'testimonial-carousal'
};

export const chainOfImpactProperties: SwiperProps = {
  slidesPerView: 1,
  // centeredSlides: false,
  slidesPerGroupSkip: 0,
  // centerInsufficientSlides: true,
  grabCursor: true,
  autoHeight: false,
  spaceBetween: 0,
  keyboard: {
    enabled: true
  },
  allowTouchMove: true,
  breakpoints: {
    1200: {
      slidesPerGroup: 1
    },
    991: {
      slidesPerGroup: 1
    },
    769: {
      slidesPerGroup: 1
    },
    575: {
      slidesPerGroup: 1
    },
    0: {
      slidesPerGroup: 1
    }
  },
  navigation: false,
  pagination: false,
  className: 'testimonial-carousal'
};
