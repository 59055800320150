/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Container } from '@mui/material';
import React from 'react';
import {
  CmsImage,
  CtaButton,
  CtaButtonProps,
  DonateCta,
  DonateCtaProps,
  Heading
} from '@bayada/shared/ui-components';
import { getBannerTextColor } from '../banner-helper';
import { BannerProps } from '../banner';
import { uuidv4 } from 'apps/bayada/utils/helper';
import FindCare, { FindCareProps } from '../../find-care/find-care';
import { MarkDown } from '@bayada/shared/ui-components';

/* The `HeroBanner` component is a functional component in TypeScript React that takes in props of type
`BannerProps`. It renders a banner with `background image`, `heading`, `subHeading`,
`text`, `backgroundImage`, `headingValue`, `textColor`, `contentAlignment`, and `sys`. */
export const HeroBanner = (props: BannerProps) => {
  const {
    heading,
    subHeading,
    text,
    backgroundImage,
    headingValue,
    textColor,
    contentAlignment,
    sys,
    findCareComponent,
    findCareComponentAlignment,
    cta,
    licenseNumber,
    cssClass,
    contentClass = 'md:w-3/4 lg:w-1/2'
  } = props || {};
  const sysId = sys?.id || uuidv4();
  const bannerId = `hero-banner-${sysId}`;
  const bannerContentId = `banner-content-${sysId}`;

  const textClass = getBannerTextColor(textColor);
  let alignmentOrigin = '';
  let contentAlignmentClass = '!justify-start !text-left';
  let textBgGradient = 'left-gradient';
  switch (contentAlignment) {
    case 'center':
      contentAlignmentClass = '!justify-center items-center !text-center';
      textBgGradient = 'center-gradient';
      alignmentOrigin = 'origin-center';
      break;
    case 'right':
      contentAlignmentClass = '!justify-end items-end !text-right';
      textBgGradient = 'right-gradient';
      alignmentOrigin = 'origin-right';
      break;
    default:
      alignmentOrigin = 'origin-left';
      break;
  }

  return (
    <div id={bannerId} className="w-full min-w-0">
      <div
        className={
          'relative flex-col hero-banner flex items-end md:items-center sm:bg-gray-800 bg-white min-h-[50vh] md:min-h-[25.5rem] sm:min-h-[14.625rem] lg:min-h-[21rem] xxxl:min-h-[39svh]'
        }
        aria-label={bannerId}
      >
        <div
          className={`hero-background bg-ba-primary-black ${textBgGradient}`}
          role="img"
          aria-label="Hero Banner Background"
        >
          {backgroundImage && (
            <CmsImage
              {...backgroundImage}
              style={{ width: '100%', height: '100%' }}
              className="hero-background object-cover object-top"
              fill
              priority={true}
            />
          )}
        </div>
        <Container
          fixed
          className={`hero-banner-content grow flex-col !flex justify-center`}
          id={bannerContentId}
          role="group"
          aria-label="Hero Banner Elements"
        >
          <div
            className={`flex w-full flex-col py-10 sm:py-16 lg:pt-24 mt-auto lg:mt-0
            ${findCareComponent ? `lg:pb-12` : `lg:pb-24`}
             ${contentAlignmentClass ?? ''}`}
            aria-labelledby={bannerContentId}
          >
            <div
              className={`grid banner-content ${contentClass} ${alignmentOrigin}`}
            >
              {heading && (
                <Heading
                  type={headingValue}
                  className={`text-shadow banner-title ${textClass} ${cssClass}`}
                  id={`banner-heading-${sysId}`}
                  ariaLevel="1"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {licenseNumber && (
                <p className="t-16-17 mt-1 font-normal">{`License: ${licenseNumber}`}</p>
              )}
              {subHeading && (
                <Heading
                  id={`banner-subheading-${sysId}`}
                  type="h4"
                  ariaLevel="2"
                  className={`text-shadow banner-sub-heading !font-bold ${textClass}`}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </Heading>
              )}
              {text && (
                <p
                  id="banner-text"
                  aria-label="banner-text"
                  className={`t-16-17 text-shadow banner-paragraph !font-frutiger set-frutiger opacity-90 ${textClass}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
                </p>
              )}
              <div
                className={`cta-holder flex gap-4 lg:gap-6  flex-col xs:flex-row xs:items-center xs:flex-wrap empty:hidden  ${contentAlignmentClass ?? ''}`}
              >
                {cta &&
                  cta?.length &&
                  cta?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="banner-cta-wrapper text-[0px] w-full xs:w-auto"
                    >
                      {String(item?.sys?.contentType?.sys?.id).toLowerCase() ===
                      'donationscta' ? (
                        <DonateCta {...(item as DonateCtaProps)} />
                      ) : (
                        <CtaButton
                          {...(item as CtaButtonProps)}
                          className=" w-full xs:w-auto"
                          hyperlinkClass="flex min-w-0"
                          sx={
                            item.buttonType === 'outlined'
                              ? {
                                  border: '1px solid var(--white)',
                                  backgroundColor:
                                    'rgba(var(--primary-black),0.1)',
                                  color: 'var(--white)',
                                  '&:hover': {
                                    backgroundColor: 'var(--white)',
                                    color: 'var(--ba-primary-black)'
                                  }
                                }
                              : {}
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          {findCareComponent &&
            findCareComponentAlignment !== 'bottom center edge' && (
              <div
                className={`flex 
              ${findCareComponentAlignment !== 'bottom center edge' ? 'justify-center mb-[2.5rem]' : `float-${findCareComponentAlignment}`} mb-4`}
              >
                <FindCare
                  {...({
                    ...findCareComponent,
                    alignment: findCareComponentAlignment,
                    isBanner: true
                  } as FindCareProps)}
                />
              </div>
            )}
        </Container>
      </div>

      {findCareComponent &&
        findCareComponentAlignment === 'bottom center edge' && (
          <Container
            fixed
            className={`hero-banner-content grow flex-col !flex justify-center mx-auto relative z-10 `}
            role="group"
            aria-label="Hero Banner Elements"
          >
            <div className="flex">
              <div
                className={`flex
              ${findCareComponent && ` mb-0 bottom-0 w-full justify-center  mt-[-1.3rem] sm:mt-[-2.3rem]  sm:mb-[-5.344rem] `} 
            mb-4`}
              >
                <FindCare
                  {...({
                    ...findCareComponent,
                    id: 'hero-banner',
                    alignment: findCareComponentAlignment,
                    isBanner: true
                  } as FindCareProps)}
                />
              </div>
            </div>
          </Container>
        )}
    </div>
  );
};
