'use client';
import React, { useCallback, useState } from 'react';
import { CarouselItem } from '../static-carousel-container';
import { Container, Stack } from '@mui/material';
import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  HeadingValue,
  ICmsImage
} from '@bayada/interfaces';
import { Document } from '@contentful/rich-text-types';
import { SliderButtonGroup } from '../slider-button-group';
import ComponentResolver from '../../component-resolver/component-resolver';
import '../static-carousel-container.scss';
import { chainOfImpactProperties } from '../carousel-constants';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface ChainOfImpactCarouselProps {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  items?: CarouselItem[];
  richText?: Document | unknown;
  cta?: CtaButtonProps | null;
  image?: ICmsImage | null;
  backgroundColor?: BackgroundColor;
}

const ChainOfImpactCarousel = (props: ChainOfImpactCarouselProps) => {
  const [showCarousel, setCarouselVisibility] = useState<boolean>(false);
  const {
    items,
    heading,
    headingValue,
    text,
    cta,
    image,
    subHeading,
    richText,
    alignment,
    backgroundColor
  } = props || {};

  // ChainOfImpactLandingSection CTA click event
  const onCtaButtonClick = (show: boolean) => {
    setCarouselVisibility(show);
  };
  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  // chain of impact slider landing component
  const ChainOfImpactLandingSection = useCallback(() => {
    return (
      <div className={`${bg} py-10 sm:py-12 md:py-14 overflow-hidden`}>
        <Container fixed aria-label="chain-of-impact-carousal">
          <div className="grid md:grid-cols-12 items-center gap-10 md:gap-0">
            <div
              className={`flex w-full md:col-span-6 order-2 md:order-1 text-${alignment} `}
            >
              <div className="flex flex-col justify-start md:mr-11">
                {heading && (
                  <Heading
                    type={headingValue}
                    className={`mb-6 text-primary-black`}
                    ariaLevel="1"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                    />
                  </Heading>
                )}
                {subHeading && (
                  <h5
                    className="t-18-21 mb-6 font-frutiger set-frutiger font-bold text-primary-black"
                    aria-label="sub-heading-label"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                    />
                  </h5>
                )}
                {text && (
                  <p
                    className="t-16-17 color-ba-gray-900"
                    aria-label="text-label"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                )}
                {cta && (
                  <div className="mt-10">
                    <CtaButton
                      {...cta}
                      onClick={() => {
                        onCtaButtonClick(true);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            {image && (
              <div className=" w-full md:col-start-8 md:col-end-13   order-1 md:order-2">
                <figure className="right-edge-sm-img-wrap ">
                  <CmsImage
                    {...image}
                    className="object-contain h-full  !w-full"
                  />
                </figure>
              </div>
            )}
          </div>
          {(richText as Document) && (
            <div
              className={`mx-auto mt-8 w-full inline-flex items-center justify-center text-center md:text-${alignment}`}
              aria-label="rich-text-container"
            >
              <CmsRichText
                value={richText as Document}
                className="t-16-17"
                font="font-inter"
              />
            </div>
          )}
        </Container>
      </div>
    );
  }, [
    bg,
    alignment,
    headingValue,
    heading,
    subHeading,
    text,
    cta,
    image,
    richText
  ]);

  const CarouselSection = useCallback(() => {
    return (
      <div className={`${bg} py-10 sm:py-12 md:py-14 overflow-hidden`}>
        <Stack
          spacing={{ base: 1, sm: 2, md: 10, lg: 15 }}
          direction="row"
          useFlexGap
        >
          <Carousel className="mySwiper" {...chainOfImpactProperties}>
            {items &&
              items.map((carouselItem, mainIndex: number) => {
                return (
                  <CarouselSlide key={mainIndex} className="full-page-swiper">
                    <Container
                      fixed
                      className="relative mx-auto  z-10"
                      aria-label="carousal-section"
                    >
                      <div className="grid md:grid-cols-12 items-center">
                        <div className="col-start-1 col-end-13 lg:col-start-1 lg:col-end-7 flex w-full flex-col items-start justify-end min-w-0">
                          <div className="grid grid-cols-12">
                            <div className="col-start-1 col-end-13 sm:col-start-1 sm:col-end-7 lg:col-start-1 lg:col-end-13">
                              {carouselItem?.title && (
                                <span
                                  className="ba-tag primary t-12 mb-5 rounded-sm font-frutiger set-frutiger font-normal uppercase"
                                  aria-label="carousel-title"
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(carouselItem?.title)
                                  }}
                                />
                              )}
                              {carouselItem?.heading && (
                                <h2
                                  className="t-33-46 mb-6 text-primary-black !font-frutiger set-frutiger !font-bold"
                                  aria-label="carousel-heading"
                                >
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html: MarkDown(carouselItem?.heading)
                                    }}
                                  />
                                </h2>
                              )}
                              {typeof text === 'string' &&
                                carouselItem?.text && (
                                  <p
                                    className="t-16-17 mb-5 color-ba-gray-900"
                                    aria-label="carousel-text"
                                  >
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html: MarkDown(carouselItem?.text)
                                      }}
                                    />
                                  </p>
                                )}
                            </div>

                            {/* only in mobile and tab */}
                            <div className=" w-full col-start-1 col-end-13  sm:col-start-8 sm:col-end-13 lg:hidden  mt-5">
                              {carouselItem?.image && (
                                <div className="right-edge-sm-img-wrap ">
                                  <figure
                                    className="flex w-full "
                                    aria-label="carousel-image"
                                  >
                                    <CmsImage
                                      imagelarge={
                                        carouselItem?.image?.imagelarge
                                      }
                                      imagemedium={
                                        carouselItem?.image?.imagemedium
                                      }
                                      imagesmall={
                                        carouselItem?.image?.imagesmall
                                      }
                                      altText={carouselItem?.image?.title}
                                      className="object-contain !w-full"
                                    />
                                  </figure>
                                </div>
                              )}
                            </div>
                          </div>
                          {carouselItem?.cardGroup && (
                            <Stack
                              spacing={{ base: 4, sm: 3, md: 4, lg: 3 }}
                              direction="row"
                              useFlexGap
                              className="flex mt-5 md:mt-7 lg:mt-9 flex-wrap sm:flex-nowrap"
                            >
                              {carouselItem?.cardGroup?.map((card, index) => {
                                return (
                                  <div
                                    className="min-w-[8.625rem] basis-full"
                                    key={index}
                                  >
                                    <ComponentResolver
                                      className="section-block"
                                      data={card}
                                    />
                                  </div>
                                );
                              })}
                            </Stack>
                          )}
                          <SliderButtonGroup
                            setShowSlider={onCtaButtonClick}
                            cta={carouselItem?.cta}
                            totalCountState={items.length}
                            currentIndex={mainIndex + 1}
                          />
                        </div>
                        <div className="w-full lg:col-start-8 lg:col-end-13 hidden lg:block ">
                          <div className="right-edge-sm-img-wrap">
                            {carouselItem?.image && (
                              <figure className="flex w-full ">
                                <CmsImage
                                  imagelarge={carouselItem?.image?.imagelarge}
                                  imagemedium={carouselItem?.image?.imagemedium}
                                  imagesmall={carouselItem?.image?.imagesmall}
                                  altText={carouselItem?.image?.title}
                                  className="object-contain !w-full  "
                                />
                              </figure>
                            )}
                          </div>
                        </div>
                      </div>
                    </Container>
                    {carouselItem?.image && (
                      <div className="client-stepper-image py-2 hidden lg:block">
                        <figure className="flex w-full h-full items-center my-auto">
                          <CmsImage
                            imagelarge={carouselItem?.image?.imagelarge}
                            imagemedium={carouselItem?.image?.imagemedium}
                            imagesmall={carouselItem?.image?.imagesmall}
                            altText={carouselItem?.image?.title}
                            className="object-contain"
                          />
                        </figure>
                      </div>
                    )}
                  </CarouselSlide>
                );
              })}
          </Carousel>
        </Stack>
      </div>
    );
  }, [bg, items, text]);
  return (
    <>{!showCarousel ? <ChainOfImpactLandingSection /> : <CarouselSection />}</>
  );
};

export default ChainOfImpactCarousel;
