'use client';
import React, { useCallback } from 'react';
import { CarouselItem } from '../static-carousel-container';
import { Container, Stack } from '@mui/material';
import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  Position,
  HeadingValue
} from '@bayada/interfaces';
import { servicesCarouselProps } from '../carousel-constants';
import CarouselWrapper from '../carousel-wrapper';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface ServicesCarouselProps {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  backgroundColor?: BackgroundColor;
  items?: CarouselItem[];
  cta?: CtaButtonProps | null;
  ctaPosition?: Omit<Position, 'left' | 'right'>;
  richText?: Document | unknown;
}
/* This code defines a functional component ServicesCarousel that takes in ServicesCarouselProps as props. 
It dynamically sets the background color based on the backgroundColor prop value. It conditionally renders 
a CtaButton component if cta prop exists. It then renders a structured layout with heading, subheading, text, 
and a CtaButton based on the props passed to it. It also maps over the items prop to render carousel slides 
with images, headings, text, and CtaButtons for each item. Additionally, it renders rich text content */
const ServicesCarousel = (props: ServicesCarouselProps) => {
  const {
    items,
    heading,
    subHeading,
    alignment,
    backgroundColor,
    headingValue,
    text,
    ctaPosition,
    cta,
    richText
  } = props || {};

  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  const ContainerCtaButton = useCallback(() => {
    if (cta) {
      return (
        <div
          className={` md:text-${alignment} ${ctaPosition === 'bottom' && 'mt-6'}`}
        >
          <CtaButton {...cta} className="ba-text-primary" />
        </div>
      );
    }
    return null;
  }, [cta, alignment, ctaPosition]);
  return (
    <div
      className={`py-10 sm:py-12 md:py-14 overflow-hidden ${bg}`}
      aria-label="services-carousal"
    >
      <Container
        fixed
        className="relative mx-auto"
        aria-label="services-carousal"
      >
        <div className="w-full mx-auto grid-cols-12 grid">
          <div
            className={`text-${alignment} col-span-12 flex flex-col gap-6
            ${alignment == 'center' ? 'lg:col-start-3 lg:col-span-8' : '   '}`}
          >
            {heading && (
              <Heading
                type={headingValue}
                className={`text-primary-black  `}
                ariaLevel="1"
              >
                <span dangerouslySetInnerHTML={{ __html: MarkDown(heading) }} />
              </Heading>
            )}
            {subHeading && (
              <h5
                className="t-18-21 font-frutiger set-frutiger font-bold text-primary-black"
                aria-label="sub-heading-label"
              >
                <span
                  dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                />
              </h5>
            )}
            {text && (
              <div className={``}>
                <p
                  className="t-16-19 mx-auto font-normal color-ba-gray-900"
                  aria-label="text-label"
                >
                  <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
                </p>
              </div>
            )}
            {ctaPosition === 'top' && <ContainerCtaButton />}
          </div>
        </div>
        {items && (
          <div className="mx-auto mt-10 lg:mt-14 w-full ba-slider-wrapper">
            <CarouselWrapper cssClass="overflow-carousel">
              <Carousel {...servicesCarouselProps}>
                {items?.map((data, index: number) => {
                  const itemCta = data?.cta;
                  const image = data?.image;
                  return (
                    <CarouselSlide key={index}>
                      <Stack
                        className="ba-hover-expand-card relative flex justify-end overflow-hidden rounded-md"
                        direction="column"
                        spacing={2}
                      >
                        <figure className="ba-hover-expand-card-pic h-full w-full">
                          {image && (
                            <CmsImage
                              {...image}
                              className="h-full w-full object-cover"
                              altText="background"
                              fill
                              style={{
                                width: '100%',
                                height: '100%'
                              }}
                            />
                          )}
                          <div className="swiper-lazy-preloader"></div>
                        </figure>
                        <div className="ba-hover-expand-card-content gradient-top-bottom inline-flex flex-col justify-end">
                          {data.heading && (
                            <h5
                              className="t-18-21 placeholder-title mb-0 font-frutiger set-frutiger !font-bold !text-white line-clamp-10"
                              aria-label="data-heading-label"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.heading)
                                }}
                              />
                            </h5>
                          )}
                        </div>
                        <div className="ba-hover-expand-card-content card-reveal flex flex-col gap-2 justify-end">
                          {data.heading && (
                            <h5
                              className="t-18-21 mb-0 font-frutiger set-frutiger !font-bold !text-white line-clamp-10 shrink-0"
                              aria-label="heading-label-hover"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.heading)
                                }}
                              />
                            </h5>
                          )}
                          <div className="ba-hover-expand-card-wrap inline-flex flex-col items-start min-h-0">
                            {typeof data.text === 'string' && data?.text && (
                              <p
                                className="t-14  !text-white line-clamp-10  overflow-y-auto custom-scrollbar transparent-scroll scroll-pr-1"
                                aria-label="text-label-hover"
                                data-lenis-prevent
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(data?.text)
                                  }}
                                />
                              </p>
                            )}

                            {itemCta && (
                              <div className="mt-5" aria-label="cta-hover">
                                <CtaButton
                                  {...itemCta}
                                  sx={{
                                    '&.MuiButtonBase-root': {
                                      '&.MuiButton-outlinedSecondary': {
                                        color: 'var(--white)',
                                        background: `linear-gradient(
                                          96deg,
                                          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 0%,
                                          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 19.27%,
                                          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 66.15%,
                                          hsl(var(--ba-red-h), var(--ba-red-s), var(--ba-red-l)) 100%
                                         )`,
                                        '&:hover': {
                                          background: `linear-gradient(
                                          96deg,
                                          hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 0%,
                                          hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 3%)) 19.27%,
                                          hsl(var(--ba-red-h), calc(var(--ba-red-s) + 3%), calc(var(--ba-red-l) - 5%)) 66.15%,
                                          hsl(var(--ba-red-h), calc(var(--ba-red-s) + 13%), calc(var(--ba-red-l) + 5%)) 100%
                                        )`
                                        }
                                      }
                                    }
                                  }}
                                  className="hover-card-cta mr-auto !border-none"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      </Stack>
                    </CarouselSlide>
                  );
                })}
              </Carousel>
            </CarouselWrapper>
            {ctaPosition === 'bottom' && <ContainerCtaButton />}
          </div>
        )}
        {(richText as Document) && (
          <div
            className={`mx-auto mt-8 w-full text-${alignment}`}
            aria-label="rich-text-container"
          >
            <CmsRichText
              value={richText as Document}
              className="t-16-17"
              font="font-inter"
            />
          </div>
        )}
      </Container>
    </div>
  );
};

export default ServicesCarousel;
