'use client';

import { CmsImage, Icon } from '@bayada/shared/ui-components';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { CmsImageProps } from '@bayada/interfaces';
import { AccordionContent } from './accordion-container';
import ComponentResolver from '../component-resolver/component-resolver';

export interface AccordionItemProps {
  item: AccordionItemContent;
  isVertical?: boolean;
  theme?: string;
  handleChange: (
    item: AccordionItemContent
    // event: React.SyntheticEvent,
    // isExpanded: boolean
  ) => void;
}

export interface AccordionItemContent {
  isExpanded?: boolean;
  heading: string;
  internalName?: string;
  icon?: CmsImageProps | null;
  content?: AccordionContent[];
  sys: { [key: string]: unknown };
  id?: string;
}

/* The `AccordionItem` component in the provided code snippet is a functional component in React that represents an individual item within an accordion component. Here is a breakdown of what the component is doing: */
const AccordionItem: React.FC<AccordionItemProps> = ({
  item,
  isVertical = false,
  theme,
  handleChange
}) => {
  const sysId = item?.sys?.id as string;
  const isAccordionExpanded = (item?.isExpanded && !isVertical) || false;

  // Handles the click event for an accordion item
  const handleAccordionClick = () => {
    handleChange(item);
  };

  return (
    <div
      className={`accordion-item-wrapper transition-colors w-full ${item?.isExpanded ? 'active' : ''}`}
    >
      <Accordion
        expanded={isAccordionExpanded}
        onChange={handleAccordionClick}
        className={`${theme === 'gray' ? 'grey-shade' : ''} `}
      >
        <AccordionSummary
          className={`${item?.isExpanded ? 'active-item' : ''}`}
          expandIcon={
            <>
              {!isVertical && (
                <Icon
                  iconName={item?.isExpanded ? 'minus' : 'plus'}
                  className="icon-24 flex items-center justify-center color-ba-primary-red"
                />
              )}
              {isVertical && (
                <Icon
                  iconName="chevronRight"
                  className="icon-24 flex items-center justify-center color-ba-primary-red"
                />
              )}
            </>
          }
          aria-controls={sysId}
          id={`${sysId}-header`}
        >
          {item?.icon && (
            <div className="flex space-between items-center mr-3">
              {item?.icon && (
                <CmsImage
                  {...item?.icon}
                  className={`object-contain object-left`}
                  width={24}
                  height={24}
                />
              )}
            </div>
          )}

          {item?.heading && (
            <h6
              className={`t-16-17 font-semibold ${isVertical ? 'transition-colors' : 'inline-flex items-center'}`}
              aria-label="Accordion-heading"
            >
              {item?.heading}
            </h6>
          )}
        </AccordionSummary>
        {!isVertical && (
          <AccordionDetails
            sx={{
              '&.MuiAccordionDetails-root': {
                paddingLeft: item?.icon ? '' : '0!important'
              }
            }}
          >
            {item?.content?.map((contentItem, index) => {
              return (
                <div key={index} className="accordion-content-wrapper w-full">
                  <ComponentResolver
                    className="section-block p0"
                    data={contentItem}
                  />
                </div>
              );
            })}
          </AccordionDetails>
        )}
      </Accordion>
    </div>
  );
};

export default AccordionItem;
