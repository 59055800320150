/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { IconButton, Stack } from '@mui/material';
import { useSwiper } from 'swiper/react';
import { CtaButton, CtaButtonProps, Icon } from '@bayada/shared/ui-components';
import './static-carousel-container.scss';
type SliderButtonGroup = {
  setShowSlider?: (showSlide: boolean) => void;
  cta?: CtaButtonProps | null;
  totalCountState: number;
  currentIndex: number;
};

/**
 * The SliderButtonGroup component is used to render the
 * next, previous, and CTA buttons for the Slider component.
 *
 * This component uses the useSwiper hook to get a reference to the
 * swiper instance. This is used to advance to the next/previous slide
 * and to determine whether the user is on the first or last slide
 * so that the "back" or "next" buttons can be disabled accordingly.
 *
 * The component takes in a prop called "setShowSlider" which is a function
 * passed from the parent component that renders the Slider. This function
 * is used to close the slider when the user is on the first slide
 * and clicks the "back" button or when the user is on the last slide
 * and clicks the "next" button.
 *
 * The component also takes in a prop called "cta" which is an object
 * containing the properties needed to render a CtaButton. If no cta is
 * provided, the CtaButton component is not rendered.
 */
export const SliderButtonGroup: React.FC<SliderButtonGroup> = ({
  setShowSlider,
  totalCountState,
  cta,
  currentIndex
}) => {
  const swiper = useSwiper();

  const handleNext = () => {
    if (swiper.isEnd) {
      if (setShowSlider) {
        setShowSlider(false);
      }
    }
    swiper.slideNext();
  };

  const handleBack = () => {
    if (swiper.activeIndex == 0) {
      if (setShowSlider) {
        setShowSlider(false);
      }
    } else {
      swiper.slidePrev();
    }
  };

  return (
    <Stack
      spacing={{ base: 3, sm: 2, md: 10, lg: 15 }}
      direction="row"
      justifyContent={'space-between'}
      useFlexGap
      sx={{
        marginTop: 9,
        width: '100%',
        flexDirection: {
          base: 'column',
          sm: 'row'
        }
      }}
      aria-label="slider-button-container"
    >
      {cta && (
        <div className="slider-cta-wrap">
          <CtaButton
            {...cta}
            sx={{
              width: {
                base: '100%',
                sm: 'auto'
              },
              maxWidth: {
                base: '340px'
              }
            }}
          />
        </div>
      )}
      <div className="flex gap-2 items-center justify-center mb-1 lg:mb-0">
        <p
          className="flex gap-1 items-center t-13 sm:mr-2 font-inter leading-snug color-ba-gray-900 order-2 sm:order-1"
          aria-label="count-label"
        >
          <span>{currentIndex}</span>of<span>{totalCountState}</span>
        </p>
        <IconButton
          className="rounded-stepper-control order-1 sm:order-2"
          onClick={handleBack}
          tabIndex={0}
          disableFocusRipple
        >
          <Icon
            iconName="rightArrow"
            className="svg-icon icon-24 rotate-180 flex items-center justify-center"
          ></Icon>
        </IconButton>
        <IconButton
          className="rounded-stepper-control order-3"
          onClick={handleNext}
          tabIndex={0}
          disableFocusRipple
        >
          <Icon
            iconName="rightArrow"
            className="svg-icon icon-24 flex items-center justify-center"
          ></Icon>
        </IconButton>
      </div>
    </Stack>
  );
};
