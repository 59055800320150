'use client';
import React, { useCallback } from 'react';
import { CarouselItem } from '../static-carousel-container';
import { Container } from '@mui/material';
import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  Position,
  HeadingValue
} from '@bayada/interfaces';
import { serviceDetailCarouselProperties } from '../carousel-constants';
import { Document } from '@contentful/rich-text-types';
import CarouselWrapper from '../carousel-wrapper';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface ServiceDetailCarouselProps {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  backgroundColor?: BackgroundColor;
  items?: CarouselItem[];
  richText?: Document | unknown;
  cta?: CtaButtonProps | null;
  ctaPosition?: Omit<Position, 'left' | 'right'>;
}
/**This code defines a functional component ServiceDetailCarousel that takes ServiceDetailCarouselProps as props.
 * It conditionally styles the background based on the backgroundColor prop value. It renders a carousel with
 * specific content based on the props received, including headings, subheadings, text, images, and call-to-action
 * buttons. The component also handles the alignment of the elements within the carousel and the position of the
 * call-to-action button. Additionally, it supports rendering rich text content using CmsRichText component based
 * on the richText prop. */
const ServiceDetailCarousel = (props: ServiceDetailCarouselProps) => {
  const {
    items,
    heading,
    subHeading,
    alignment,
    backgroundColor,
    headingValue,
    text,
    cta,
    ctaPosition,
    richText
  } = props || {};

  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  const ContainerCtaButton = useCallback(() => {
    if (cta) {
      return (
        <div
          className={` md:text-${alignment} ${ctaPosition === 'bottom' && 'mt-6'}`}
        >
          <CtaButton
            {...cta}
            className="t-15 font-frutiger font-medium leading-snug"
          />
        </div>
      );
    }
    return null;
  }, [cta, alignment, ctaPosition]);
  const slideCount = items?.length || 0;
  const colStart =
    slideCount > 2
      ? 'lg:col-start-1 lg:col-span-12'
      : 'lg:col-start-3 lg:col-span-8';
  return (
    <>
      <div className={`py-10 sm:py-12 md:py-14 overflow-hidden ${bg}`}>
        <Container
          fixed
          className="relative mx-auto"
          aria-label="services-details-carousel"
        >
          <div className="w-full mx-auto grid-cols-12 grid">
            <div
              className={`text-${alignment} flex  flex-col gap-6
            ${alignment == 'center' ? 'col-start-1 col-end-13 lg:col-start-2 lg:col-end-12' : 'col-span-12'}`}
            >
              {heading && (
                <Heading type={headingValue} className={` text-primary-black`}>
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <h5
                  className="t-18-21  font-frutiger set-frutiger font-bold text-primary-black"
                  aria-label="sub-heading-label"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </h5>
              )}
              {text && (
                <div>
                  <p
                    className="t-16-19 font-normal color-ba-gray-900"
                    aria-label="text-label"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                </div>
              )}
              {ctaPosition === 'top' && <ContainerCtaButton />}
            </div>
          </div>
          <div className="mx-auto mt-10 lg:mt-14 w-full md:grid-cols-12 md:grid">
            <div className={`  md:col-span-10 ${colStart}`}>
              <CarouselWrapper cssClass="overflow-carousel">
                <Carousel {...serviceDetailCarouselProperties}>
                  {items?.map((data, index: number) => {
                    const image = data?.image;
                    return (
                      <CarouselSlide key={index}>
                        <div className="lg:w-100 relative mx-auto w-full img-expansion">
                          {image && (
                            <figure className="flex z-0 relative overflow-hidden w-full min-h-[31.25rem] rounded-lg">
                              <CmsImage
                                {...image}
                                className="absolute top-0 left-0 w-full h-full z-0 object-cover"
                                width={888}
                                height={500}
                                altText="about-service-image-1"
                              />
                              <div className="absolute top-0 left-0 flex w-full h-full z-10 gradient-from-transparent-to-black"></div>
                              <div className="swiper-lazy-preloader"></div>
                            </figure>
                          )}
                          <div className="absolute w-full bottom-0 left-0 p-6 z-10 max-h-[75%] flex flex-col gap-2">
                            {data?.heading && (
                              <h5
                                className=" shrink-0 font-bold text-white t-18-21  cut-text-image-description"
                                aria-label="carousel-heading"
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(data?.heading)
                                  }}
                                />
                              </h5>
                            )}
                            {typeof data?.text === 'string' && data?.text && (
                              <p
                                className=" flex-1 font-normal t-14-15 text-white leading-normal overflow-y-auto  custom-scrollbar transparent-scroll scroll-pr-1"
                                aria-label="carousel-text"
                                data-lenis-prevent
                              >
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html: MarkDown(data?.text)
                                  }}
                                />
                              </p>
                            )}
                          </div>
                        </div>
                      </CarouselSlide>
                    );
                  })}
                </Carousel>
              </CarouselWrapper>
              {ctaPosition === 'bottom' && <ContainerCtaButton />}
            </div>
          </div>
          {(richText as Document) && (
            <div
              className={`mx-auto mt-8 w-full text-${alignment}`}
              role="region"
              aria-label="rich-text-container"
            >
              <CmsRichText
                value={richText as Document}
                className="t-16-17"
                font="font-inter"
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ServiceDetailCarousel;
