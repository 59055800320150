'use client';
import React from 'react';
import { Stack } from '@mui/material';
import {
  Carousel,
  CarouselSlide,
  CmsImage
} from '@bayada/shared/ui-components';
import { ICmsImage } from '@bayada/interfaces';

interface WorkPlaceCertificateProps {
  images: ICmsImage[];
}

const WorkplaceCertificate = ({ images }: WorkPlaceCertificateProps) => {
  return (
    <div className="ba-slider-wrapper my-8">
      <Carousel
        slidesPerView={2.5}
        centeredSlides={false}
        slidesPerGroupSkip={3}
        centerInsufficientSlides={true}
        grabCursor={true}
        keyboard={{
          enabled: true
        }}
        breakpoints={{
          1200: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 20
          },
          991: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 20
          },
          769: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 10
          },
          575: {
            slidesPerView: 2,
            slidesPerGroup: 1,
            spaceBetween: 8
          },
          0: {
            slidesPerView: 1.2,
            slidesPerGroup: 1,
            spaceBetween: 8
          }
        }}
        scrollbar={{ draggable: true }}
        allowTouchMove={true}
        navigation={false}
        pagination={false}
        className="mySwiper"
      >
        {images.map((item, index) => (
          <CarouselSlide key={index}>
            <Stack
              className="relative flex justify-end overflow-hidden rounded-md h-full w-full"
              direction="column"
              spacing={2}
            >
              <div
                className="h-full w-full"
                aria-label="workplace-certificate-container"
              >
                {item && (
                  <CmsImage
                    {...item}
                    className="!w-full object-contain max-h-screen"
                  />
                )}
              </div>
            </Stack>
          </CarouselSlide>
        ))}
      </Carousel>
    </div>
  );
};

export default WorkplaceCertificate;
