import {
  Alignment,
  CmsImageProps,
  HeadingValue,
  TextColor
} from '@bayada/interfaces';
import { HeroBanner } from './variants/hero-banner';
import './banner.scss';
import { CtaButtonProps, DonateCtaProps } from '@bayada/shared/ui-components';
import LeftAlignedContentBanner from './variants/left-aligned-content-banner';
import CenterAlignedContentBanner from './variants/center-aligned-content-banner';
import RightAlignedContentBanner from './variants/right-aligned-content-banner';
import { FindCareProps } from '../find-care/find-care';

export interface BannerProps {
  internalName: string;
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  form?: { [key: string]: undefined };
  findCareComponentAlignment?:
    | Omit<Alignment, 'bottom' | 'right'>
    | 'bottom center edge';
  backgroundImage?: CmsImageProps | null;
  contentAlignment?: Omit<Alignment, 'bottom'>;
  textColor?: TextColor;
  backgroundColor?: string;
  cta?: CtaButtonProps[] | DonateCtaProps[] | null;
  image?: CmsImageProps | null;
  cssClass?: string;
  contentClass?: string;
  style?: React.CSSProperties;
  sys?: { [key: string]: unknown };
  role?: string;
  findCareComponent?: FindCareProps | null;
  licenseNumber?: string | null;
}

/**
 * The function `constructBanner` dynamically renders different types of content-aligned banners based
 * on the `contentAlignment` prop.
 * @param {BannerProps} props - The `props` parameter in the `constructBanner` function is of type
 * `BannerProps`. It is an object that contains various properties used to configure the banner
 * component. These properties may include `contentAlignment`, which determines the alignment of the
 * content within the banner. The function uses the `contentAlignment
 * @returns The `constructBanner` function returns a JSX element based on the `contentAlignment`
 * property of the `props` object. If `contentAlignment` is 'left', it returns a
 * `<LeftAlignedContentBanner />` element. If it is 'center', it returns a `<CenterAlignedContentBanner
 * />` element. If it is 'right', it returns a `<RightAlignedContentBanner />`
 */
const constructBanner = (props: BannerProps): JSX.Element => {
  switch (props?.contentAlignment) {
    case 'left':
      return <LeftAlignedContentBanner {...props} />;
    case 'center':
      return <CenterAlignedContentBanner {...props} />;
    case 'right':
      return <RightAlignedContentBanner {...props} />;
    default:
      // load left side text and right side image banner as defualt
      return <LeftAlignedContentBanner {...props} />;
  }
};

/**
 * The Banner function conditionally renders a HeroBanner component based on the presence of a
 * backgroundImage prop as per the requirements.
 * @param {BannerProps} props - The `props` parameter in the `Banner` function is an object that
 * contains the properties passed to the `Banner` component. These properties can include
 * `backgroundImage` and `role`, among others. The `Banner` component checks if `backgroundImage` is
 * provided in the props, and if
 * @returns If the `backgroundImage` prop is truthy, a `<header>` element with the class "app-header"
 * and the specified `role` attribute is returned, containing a `HeroBanner` component with the props
 * passed to the `Banner` component.
 */
export function Banner(props: BannerProps) {
  const { backgroundImage, role } = props || {};
  if (backgroundImage) {
    return (
      <header className="app-header" role={role} aria-label="Main Banner">
        <HeroBanner {...props} />
      </header>
    );
  }

  return constructBanner(props);
}

export default Banner;
