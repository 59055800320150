'use client';
import React, { useCallback } from 'react';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { CmsVideoProps, getWrapperProps } from '@bayada/shared/ui-components';
import { Heading, WrapperContainer } from '@bayada/shared/ui-components';
import ComponentResolver from '../component-resolver/component-resolver';
import {
  CmsImageProps,
  HeadingValue,
  ImagePosition,
  Sys
} from '@bayada/interfaces';
import { MarkDown } from '@bayada/shared/ui-components';
import dynamic from 'next/dynamic';
export interface ImageAndItemProps {
  internalName?: string;
  heading?: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  media: CmsImageProps | CmsVideoProps | null;
  imagePosition?: ImagePosition;
  backgroundColor?: string;
  item: any;
  sys?: Sys;
  verticalAlignment?: string;
}

const CmsVideo = dynamic<CmsVideoProps>(
  () =>
    import('@bayada/shared/ui-components').then((module) => module.CmsVideo),
  {
    loading: () => <p>Loading...</p>
  }
);

const CmsImage = dynamic<CmsImageProps>(
  () =>
    import('@bayada/shared/ui-components').then((module) => module.CmsImage),
  {
    loading: () => <p>Loading...</p>
  }
);

/* The below code is a TypeScript React component called `ImageAndItem` that takes in props and renders a section with an image or video component along with dynamic components/items based on the imagePosition. */
export function ImageAndItem(props: ImageAndItemProps) {
  const {
    headingValue = 'h3',
    imagePosition = 'image left edge',
    heading = '',
    subHeading,
    text,
    media,
    item,
    backgroundColor = '',
    verticalAlignment
  } = props || {};

  const isImageMedia = (media as CmsImageProps)?.imagelarge ? true : false;

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  let bgClass = 'bg-transparent';
  if (backgroundColor === 'grey') {
    bgClass = 'bg-ba-gray-75';
  } else if (backgroundColor === 'light blue') {
    bgClass = 'bg-ba-blue-150';
  } else if (backgroundColor === 'white') {
    bgClass = 'bg-white';
  }

  const verticalAlignClass =
    verticalAlignment == 'top' ? 'items-start' : 'items-center';

  /* The `renderMediaComponent` function is a callback function created using the `useCallback` hook in React. It is responsible for rendering either a `CmsImage` component or a `CmsVideo` component based on the value of `isImageMedia`. */
  const renderMediaComponent = useCallback(() => {
    if (isImageMedia) {
      const cmsImage = media as CmsImageProps;
      const rightEdgeImageStyle =
        imagePosition === 'image right edge' ? { height: 'fit-content' } : {};
      return (
        <figure className={`w-full flex`}>
          {cmsImage && (
            <CmsImage
              {...cmsImage}
              className="object-cover lg:rounded-lg will-change-[filter]"
              style={{ ...rightEdgeImageStyle, width: '100%' }}
            />
          )}
        </figure>
      );
    } else {
      const cmsVideo = media as CmsVideoProps;
      return <CmsVideo {...cmsVideo} />;
    }
  }, [imagePosition, isImageMedia, media]);

  /* The `renderItemComponent` function is a callback function created using the `useCallback` hook in React. It is responsible for mapping over the `item (dynamic components)` array and using `ComponentResolver`  for each item in the array to render the component dynamically. Each `ComponentResolver` component is passed the `data` prop with the corresponding item data and a unique `key` based on the item's `sys.id`. */
  const renderItemComponent = useCallback(() => {
    const ItemComponents = item?.map((component: any, index: number) => {
      return (
        <ComponentResolver
          className="section-block"
          data={component}
          key={index}
        />
      );
    });
    if (!item) {
      return <></>;
    }
    return ItemComponents;
  }, [item]);

  /* The below code is defining four functional components in a TypeScript React application. Each
component represents a different layout for displaying content with an image on either the left/left-edge or right/right-edge side. */
  const ImageLeftSide = useCallback(() => {
    return (
      <div className={`flex items-center w-full ${bgClass}`}>
        <WrapperContainer
          wrapper={wrapperContainer}
          className={`content-section-container`}
          containerCoverClass="py-10 sm:py-12 md:py-14"
        >
          <div
            className={`content-section-grid grid grid-cols-12 ${verticalAlignClass}`}
          >
            <div className="mb-8 lg:mb-0 col-start-1 col-end-13 lg:col-start-1 lg:col-end-6">
              <div
                className={`flex flex-col min-w-0 empty:hidden ${media && 'mb-10'}`}
              >
                {heading && (
                  <Heading
                    type={headingValue}
                    className={`${subHeading || text ? 'mb-4' : 'mb-0'}`}
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                    />
                  </Heading>
                )}
                {subHeading && (
                  <h2
                    className={`t-21-18 ${text ? 'mb-4' : 'mb-0'} font-frutiger set-frutiger font-bold`}
                    aria-label="left-side-subheading"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                    />
                  </h2>
                )}
                {text && (
                  <p
                    className={`t-16-17 mb-0 color-ba-gray-900`}
                    aria-label="left-side-text"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                )}
              </div>
              <figure className="w-full inline-flex rounded-lg overflow-hidden">
                {renderMediaComponent()}
              </figure>
            </div>
            <div className="w-full col-start-1 col-end-13 lg:col-start-7 lg:col-end-13 empty:hidden">
              <div className="w-full grid gap-6 content-start empty:hidden item-stack">
                {renderItemComponent()}
              </div>
            </div>
          </div>
        </WrapperContainer>
      </div>
    );
  }, [
    bgClass,
    heading,
    headingValue,
    renderItemComponent,
    renderMediaComponent,
    subHeading,
    text,
    wrapperContainer
  ]);

  const ImageRightSide = useCallback(() => {
    return (
      <div className={`flex items-center w-full ${bgClass}`}>
        <WrapperContainer
          wrapper={wrapperContainer}
          className={`content-section-container`}
          containerCoverClass="py-10 sm:py-12 md:py-14"
        >
          <div
            className={`content-section-grid grid grid-cols-12 ${verticalAlignClass}`}
            aria-label="image-and-item-container"
          >
            <div className="w-full col-start-1 col-end-13 lg:col-start-1 lg:col-end-7 empty:hidden">
              <div className="w-full lg:mr-auto grid gap-6 content-start empty:hidden item-stack">
                {renderItemComponent()}
              </div>
            </div>
            <div className="mt-8 w-full lg:mt-0 col-start-1 col-end-13 lg:col-start-8 lg:col-end-13">
              <div
                className={`flex flex-col min-w-0 empty:hidden ${media && 'mb-10'}`}
              >
                {heading && (
                  <Heading
                    type={headingValue}
                    className={`${subHeading || text ? 'mb-4' : 'mb-0'}`}
                    aria-label="right-side-heading"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                    />
                  </Heading>
                )}
                {subHeading && (
                  <h2
                    className={`t-21-18 ${text ? 'mb-4' : 'mb-0'} font-frutiger set-frutiger font-bold`}
                    aria-label="right-side-subheading"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                    />
                  </h2>
                )}
                {text && (
                  <p
                    className={`t-16-17 mb-0 color-ba-gray-900`}
                    aria-label="right-side-text"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                )}
              </div>
              <figure className="w-full inline-flex rounded-lg overflow-hidden">
                {renderMediaComponent()}
              </figure>
            </div>
          </div>
        </WrapperContainer>
      </div>
    );
  }, [
    bgClass,
    heading,
    headingValue,
    renderItemComponent,
    renderMediaComponent,
    subHeading,
    text,
    wrapperContainer
  ]);

  const ImageRightEdge = useCallback(() => {
    return (
      <div className={`flex flex-col items-center w-full ${bgClass}`}>
        <WrapperContainer
          wrapper={wrapperContainer}
          className={`relative mx-auto`}
          containerCoverClass="pt-8 pb-6 sm:py-10 sm:pb-8 md:pt-14 md:pb-10 lg:py-14"
        >
          <div
            className={`grid w-full grid-cols-12 ${verticalAlignClass}`}
            aria-label="right-edge-container"
          >
            <div className="flex w-full flex-col gap-4 item-stack col-start-1 col-end-13 lg:col-start-1 lg:col-end-8 fhd:col-start-1 fhd:col-end-7">
              {renderItemComponent()}
            </div>
            <div className="relative w-full lg:col-start-9 lg:col-end-13 fhd:col-start-8 fhd:col-end-13 hidden lg:grid items-center">
              <figure className="right-edge-img-wrap rounded-l-lg overflow-hidden">
                {renderMediaComponent()}
              </figure>
            </div>
          </div>
        </WrapperContainer>
        <figure className={`w-full flex lg:hidden empty:hidden`}>
          {/* For lower resolutions */}
          {renderMediaComponent()}
        </figure>
      </div>
    );
  }, [
    media,
    bgClass,
    renderItemComponent,
    renderMediaComponent,
    wrapperContainer
  ]);

  const ImageLeftEdge = useCallback(() => {
    return (
      <div className={`flex flex-col items-center w-full`}>
        <WrapperContainer
          wrapper={wrapperContainer}
          className={`relative mx-auto`}
          containerCoverClass={`flex flex-col pt-8 pb-6 sm:py-10 sm:pb-8 md:pt-14 md:pb-10 lg:py-14 w-full ${bgClass}`}
        >
          <div className={`grid w-full grid-cols-12 ${verticalAlignClass}`}>
            <div className="relative col-start-1 col-end-13 lg:col-start-1 lg:col-end-5 fhd:col-start-1 fhd:col-end-6 lg:order-first order-last hidden lg:grid items-center col-span-12 lg:col-span-4 fhd:col-span-5">
              <figure className="left-edge-img-wrap rounded-r-lg overflow-hidden">
                {renderMediaComponent()}
              </figure>
            </div>
            <div className="col-start-1 col-end-13 lg:col-start-6 lg:col-end-13 fhd:col-start-7 fhd:col-end-13 flex w-full flex-col gap-4 item-stack">
              {renderItemComponent()}
            </div>
          </div>
        </WrapperContainer>
        {/* For lower resolutions */}
        <figure className={`w-full flex lg:hidden empty:hidden`}>
          {renderMediaComponent()}
        </figure>
      </div>
    );
  }, [
    bgClass,
    media,
    renderItemComponent,
    renderMediaComponent,
    wrapperContainer
  ]);

  /* It determines the layout of the content based on the `imagePosition` prop passed to the component. */
  if (imagePosition === 'image left side') {
    return ImageLeftSide();
  } else if (imagePosition === 'image right side') {
    return ImageRightSide();
  } else if (imagePosition === 'image right edge') {
    // Image left edge and Image right edge positioning is not applicable for video
    return isImageMedia ? ImageRightEdge() : ImageRightSide();
  }
  // Image left edge and Image right edge positioning is not applicable for video
  return isImageMedia ? ImageLeftEdge() : ImageLeftSide();
}

export default ImageAndItem;
