/* eslint-disable @typescript-eslint/no-explicit-any */
import NavigationLink, {
  NavigationLinkProps
} from '../navigation-link/navigation-link';

export interface MenuItemGroupProps {
  sectionTitle: string;
  childItems?: NavigationLinkProps[] | null;
  sys?: any;
  type?: string;
  className?: string;
}

/**
 * The `MenuItemGroup` function in TypeScript React renders a group of menu items with a specified
 * section title and child items.
 * @param {MenuItemGroupProps} props - The `MenuItemGroup` component takes in the following props:
 * @returns The `MenuItemGroup` component is being returned. It renders a section title and a list of
 * child items as NavigationLinks within a div container. Each child item is mapped over and rendered
 * with the specified props and styling.
 */
export function MenuItemGroup(props: MenuItemGroupProps) {
  const {
    sectionTitle,
    childItems,
    type = 'menuitem',
    className = ''
  } = props || {};
  return (
    <div className={`inline-flex flex-col`}>
      {sectionTitle && (
        <h2
          className="t-14 font-frutiger mb-4 font-semibold"
          aria-label="menu-item-section-title"
        >
          {sectionTitle}
        </h2>
      )}
      {childItems?.map((item, index: number) => {
        return (
          <NavigationLink
            {...item}
            key={index}
            type={type}
            className={`${className ?? ''} !mb-2 t-13 hover:underline focus:underline w-auto mr-auto font-frutiger`}
          />
        );
      })}
    </div>
  );
}

export default MenuItemGroup;
