'use client';
import { Grid } from '@mui/material';
import {
  DisableAnimationsMobile,
  StringCountUp,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { Heading } from '@bayada/shared/ui-components';
import { m, LazyMotion, domAnimation } from 'framer-motion';
import { MarkDown } from '@bayada/shared/ui-components';

export interface LabelAndValueProps {
  label?: string;
  value?: string;
}

export interface StatsContainerProps {
  statistics: LabelAndValueProps[] | null;
  heading: string;
  headingValue?: 'h2' | 'h3' | 'h4' | 'h5';
  alignment?: 'left' | 'right' | 'center';
  columns: number;
  style: string;
  backgroundColor?: string;
  sys?: unknown;
}

/*
Function : StatsContainer

The StatsContainer component is a function component that takes the provided props to render a container for displaying statistics.
Alignment Styles: The alignmentCss variable is used to determine the alignment of the statistics container based on the provided alignment prop.
LabelAndValueComponent: This is a nested function component used to render either a label or a value based on the provided data and styling. It checks the styling property to decide whether to display a label or a value.
Rendering:
The component renders a heading (if provided) using the Heading component.
It renders a container for statistics using the Grid component from MUI.
Inside the grid, it maps over the provided statistics array and renders each statistic as a grid item.
Each grid item contains a styled container with either a label or a value based on the LabelAndValueComponent.
Wrapper Container: The WrapperContainer component is used to wrap the content and applies additional styling if provided.
*/

export function StatsContainer(props: StatsContainerProps) {
  const {
    heading,
    headingValue = 'h2',
    style = 'box',
    statistics,
    alignment = 'center',
    backgroundColor,
    columns = 3
  } = props || {};
  let alignmentCss = 'items-center text-center';

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  if (alignment?.toLocaleLowerCase() === 'left') {
    alignmentCss = 'sm:items-start sm:text-left text-center';
  } else if (alignment?.toLocaleLowerCase() === 'right') {
    alignmentCss = 'sm:items-end sm:text-right text-center';
  }

  const bg =
    backgroundColor === 'light grey'
      ? 'bg-ba-gray-75'
      : backgroundColor === 'light blue'
        ? 'bg-ba-blue-150'
        : backgroundColor === 'white'
          ? 'bg-white'
          : 'bg-transparent';

  return (
    <div
      aria-label="Statistics Container"
      role="group"
      className="flex flex-col items-center justify-center"
    >
      <WrapperContainer
        className={`mx-auto flex py-10 sm:py-12 md:py-14 ${columns > 3 ? 'xxl:!max-w-screen-xl' : 'lg:!max-w-5xl'}`}
        wrapper={wrapperContainer}
        containerCoverClass={`${bg}`}
      >
        {heading && (
          <Heading
            type={headingValue}
            className={`mb-0 w-full ${alignmentCss}`}
            ariaLevel="1"
          >
            <span dangerouslySetInnerHTML={{ __html: MarkDown(heading) }} />
          </Heading>
        )}
        <div
          className={`${heading && 'pt-10'} flex w-full items-center justify-center`}
        >
          <Grid
            container
            spacing={{ base: 3, lg: 3, xl: 3 }}
            columns={{
              base: 1,
              sm: 2,
              lg: columns
            }}
          >
            {statistics?.map(
              (statisticsItem: LabelAndValueProps, index: number) => (
                <Grid
                  item
                  base={1}
                  key={index}
                  aria-label="stats-container"
                  className="min-w-[0]"
                >
                  <LazyMotion features={domAnimation}>
                    <DisableAnimationsMobile>
                      <m.div
                        viewport={{ once: true }}
                        initial={{
                          opacity: '0',
                          transform: 'translate(0 , 20px)'
                        }}
                        transition={{
                          duration: 0.9,
                          ease: 'backInOut'
                        }}
                        whileInView={{
                          opacity: 1,
                          transform: 'translate(0px , 0px)'
                        }}
                        className={`gap-2 flex h-full flex-col will-change-[opacity,transform] ${alignmentCss} justify-start md:py-8 lg:py-4 ${style === 'box' ? 'border-[1px] border-solid rounded-lg border-ba-gray-90 py-6 px-4 lg:min-h-[12.5rem] justify-center' : ''}`}
                      >
                        {statisticsItem?.label && (
                          <h3 className="t-12 font-frutiger font-normal uppercase color-ba-gray-900 w-full cursor-default">
                            {statisticsItem?.label}
                          </h3>
                        )}
                        {statisticsItem?.value && (
                          <div className="block min-w-0 w-full">
                            <StringCountUp
                              className="truncate t-33-46 leading-tight text-primary-black font-frutiger font-bold w-full"
                              value={statisticsItem?.value}
                            />
                          </div>
                        )}
                      </m.div>
                    </DisableAnimationsMobile>
                  </LazyMotion>
                </Grid>
              )
            )}
          </Grid>
        </div>
      </WrapperContainer>
    </div>
  );
}

export default StatsContainer;
