'use client';
import React, { useCallback } from 'react';
import { Container } from '@mui/material';
import CarouselWrapper from '../carousel-wrapper';

import {
  Carousel,
  CarouselSlide,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  Position,
  HeadingValue
} from '@bayada/interfaces';
import { testimonialProperties } from '../carousel-constants';
import Quote, { QuoteProps } from '../../quote/quote';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface TestimonialCarouselProps {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  backgroundColor?: BackgroundColor;
  items?: QuoteProps[];
  cta?: CtaButtonProps | null;
  ctaPosition?: Omit<Position, 'left' | 'right'>;
  richText?: Document | unknown;
}

/**
 * This component renders a testimonial carousel. It takes props for the
 * following:
 * - Items: An array of testimonials data to be displayed in the carousel
 * - Heading: A string for the main heading of the carousel
 * - SubHeading: A string for the sub heading of the carousel
 * - Text: A string for the text below the carousel
 * - Alignment: A string for the text alignment of the Heading, SubHeading, Text, and CTA
 * - BackgroundColor: A string for the background color of the carousel
 * - HeadingValue: An integer for the heading tag to be used (e.g. H1, H2, etc.)
 * - CtaPosition: A string for the position of the CTA (either top or bottom)
 * - Cta: An object for the CTA properties
 * - RichText: A document for additional rich text below the carousel
 */
const TestimonialCarousel = (props: TestimonialCarouselProps) => {
  const {
    items,
    heading,
    subHeading,
    text,
    alignment,
    backgroundColor,
    headingValue,
    ctaPosition,
    cta,
    richText
  } = props || {};
  // Set the background color based on the backgroundColor prop
  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  // Use a callback function to render the CTA if it exists
  const ContainerCtaButton = useCallback(() => {
    if (cta) {
      return (
        <div
          className={` md:text-${alignment} ${ctaPosition === 'bottom' && 'mt-6'}`}
        >
          <CtaButton {...cta} className="ba-text-primary" />
        </div>
      );
    }
    return null;
  }, [cta, alignment, ctaPosition]);

  return (
    <>
      <div className={`py-10 sm:py-12 md:py-14 overflow-hidden ${bg}`}>
        <Container
          fixed
          className="relative mx-auto"
          aria-label="testimonial-carousel"
        >
          <div className="w-full mx-auto lg:grid-cols-12 grid">
            <div
              className={`text-${alignment} flex flex-col gap-6 col-span-1 lg:col-span-12
            ${alignment == 'center' ? 'lg:col-start-3 lg:col-span-8' : ''}`}
            >
              {heading && (
                <Heading type={headingValue} className={` text-primary-black`}>
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <h5
                  className={`t-18-21  font-frutiger  set-frutiger font-bold text-primary-black`}
                  aria-label="sub-heading-label"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </h5>
              )}
              {text && (
                <p
                  className="t-16-19 mx-auto font-normal color-ba-gray-900"
                  aria-label="text-label"
                >
                  <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
                </p>
              )}
              {ctaPosition === 'top' && <ContainerCtaButton />}
            </div>
          </div>
          <div className="mt-14 w-full">
            <CarouselWrapper cssClass="overflow-carousel">
              <Carousel {...testimonialProperties}>
                {items?.map((item, index) => (
                  <CarouselSlide key={index} className="h-auto">
                    <div className="user-card-wrap flex flex-col items-center">
                      <Quote
                        designation={item?.designation}
                        author={item?.author}
                        isGrouped
                        text={item?.text}
                        image={item?.image}
                      />
                    </div>
                  </CarouselSlide>
                ))}
              </Carousel>
            </CarouselWrapper>
            {ctaPosition === 'bottom' && <ContainerCtaButton />}
          </div>
          {(richText as Document) && (
            <div
              className={`mx-auto mt-8 w-full text-${alignment}`}
              aria-label="rich-text-container"
            >
              <CmsRichText
                value={richText as Document}
                className="t-16-17"
                font="font-inter"
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default TestimonialCarousel;
