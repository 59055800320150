/* eslint-disable @typescript-eslint/no-explicit-any */
import './cta-modal-popup.scss';
import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { IconButton } from '@mui/material';
import { Icon } from '@bayada/shared/ui-components';
import NavigationLink from '../navigation-link/navigation-link';

export interface CtaPopupProps {
  popupLinks?: any;
  open: boolean;
  handleClose: () => void;
}

/* This code snippet is a TypeScript React component called `CtaPopup`. It is a modal popup component
that displays Call-to-Action (CTA) links. Here is a breakdown of what the code does: */

export function CtaPopup(props: CtaPopupProps) {
  const { popupLinks, open, handleClose } = props || {};

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      className="overscroll-contain"
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <div
          className="modal-content absolute overflow-y-auto bg-white px-4 py-4 rounded-lg shadow-md shadow-black"
          data-lenis-prevent
        >
          <IconButton
            className="!absolute right-1 top-1 text-ba-primary-black"
            onClick={handleClose}
          >
            <Icon
              iconName="close"
              className="svg-icon icon-24 flex items-center justify-center"
            ></Icon>
          </IconButton>

          <div className="grid grid-cols-1 sm:grid-cols-2">
            {popupLinks?.map((link: any, itemIndex: number) => (
              <div key={itemIndex} className={`ctalink`}>
                <NavigationLink {...link} type={'menuitem'} className="p-0" />
              </div>
            ))}
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default CtaPopup;
