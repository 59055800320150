/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Alert, CmsImage, Icon } from '@bayada/shared/ui-components';
import { Button, Container, Link, Tooltip } from '@mui/material';
import { AnimatePresence, m, LazyMotion, domAnimation } from 'framer-motion';
import { menuSlide, slide } from './navigation-animation.constant';
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import { NavigationHeaderProps } from '../navigation-header';
import '../navigation-header.scss';
import NavigationLink, {
  NavigationLinkProps
} from '../../navigation-link/navigation-link';
import { SiteSearchInputField } from '../site-search-input/site-search-input';
import { useContentfulInspectorMode } from '@contentful/live-preview/react';
import { useDeviceSize, useScrollDirection } from '@bayada/hooks';
import { breakpoints } from '@bayada/assets';
import { usePathname } from 'next/navigation';
import { homePageUrl } from 'apps/bayada/constants';

type PageLogo = {
  altText?: string;
  ariaLabel?: string;
  internalName?: string;
  icon?: any;
  iconPosition?: string;
  imageLarge?: any;
};

export type NavigationDrawerProps = NavigationHeaderProps & {
  homePageLogo?: PageLogo;
  commonLogo?: PageLogo;
  drawerOpen?: (drawerState?: boolean) => void;
  pathname?: string;
  updatedState?: any;
  setHamburgerWrap?: any;
};

/*
Function : NavigationDrawer

Used for Mobile and Tablet resolution mainly
The NavigationDrawer component is defined, extending the functionality of the NavigationHeader component. It takes props of type NavigationHeaderProps.
The component uses the useState hook to manage different states, such as whether the drawer is open (isOpen), the active index for accordion-style navigation, and the visibility of the search bar (isSearchOpen).
There are several event handler functions defined, such as toggleDrawerBtn for toggling the drawer's visibility, toggleAccordion for handling accordion-style navigation, and handleSearchClick for toggling the visibility of the search bar.
The main render method returns JSX code representing the navigation drawer. It includes buttons, icons, and various UI elements for navigation. The layout is responsive, adapting to different screen sizes with classes like lg:hidden.
The component utilizes the framer-motion library for animations. The navigation menu slides in and out with motion effects, providing a smooth user experience.
Some components, such as the search bar and dropdown menu, are conditionally rendered based on the state.
The code maps through arrays of menu items to dynamically generate navigation links and dropdowns.

External Dependencies: The component relies on external styles and possibly other components (Curve and NavigationLink) for additional styling and functionality.
*/

export function NavigationDrawer(props: NavigationDrawerProps) {
  const { updatedState, setHamburgerWrap } = props || {};
  const [isOpen, setIsOpen] = useState(false);
  const [disableGtmApi, setDisableGtmApi] = useState(false);
  const hamburgerRef = useRef<HTMLDivElement>(null);
  const appMenuRef = useRef<HTMLDivElement>(null);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const [topnavScrollTimer, setTopnavScrollTimer] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [activeIndices, setActiveIndices] = useState<number[]>([]);
  const pathname = usePathname();
  const scrollWidthBuffer = 70;
  const minScrollTime = 3;

  const [width] = useDeviceSize();
  const [openToolTip, setOpenToolTip] = useState(false);

  const handleMouseTooltipEnter = () => {
    if (isOverflowing && props?.topNavigation?.alert?.text) {
      setOpenToolTip(true);
    }
  };
  const handleMouseTooltipLeave = () => {
    setOpenToolTip(false);
  };
  const handleScroll = () => {
    setOpenToolTip(false);
  };

  const toggleDrawerBtn = () => {
    setIsOpen(!isOpen);
    setIsSearchOpen(false);
    if (props?.drawerOpen) {
      props.drawerOpen(!isOpen);
    }
  };

  const toggleAccordion = (index: number) => {
    setActiveIndices((prevActiveIndices) => {
      if (prevActiveIndices.includes(index)) {
        return prevActiveIndices.filter((idx) => idx !== index);
      } else {
        return [...prevActiveIndices, index];
      }
    });
  };

  const { scrollY } = useScrollDirection();
  const pagesWithTransparentNav = [homePageUrl];
  const isTransparentNav = pagesWithTransparentNav?.includes(
    props?.pathname ? props.pathname : ''
  );
  const isAlertExpired =
    new Date() > new Date(props?.topNavigation?.alert?.expirationDate ?? '');
  const logo =
    isTransparentNav && scrollY <= 150 && !isOpen
      ? props?.primaryNavigation?.homePageLogo?.imageLarge?.file?.url
      : props?.primaryNavigation?.commonLogo?.imageLarge?.file?.url;

  const handleSearchClick = () => {
    onGtmClick();
    setIsSearchOpen(!isSearchOpen);
    if (isOpen) {
      setIsOpen(!isOpen);
      props?.drawerOpen?.();
    }
  };
  const inspectorProperties = useContentfulInspectorMode();
  useEffect(() => {
    const handleResize = () => {
      const hamburgerRefHeight = hamburgerRef.current?.clientHeight;
      setHamburgerWrap(hamburgerRefHeight);
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (hamburgerRef.current) {
      resizeObserver.observe(hamburgerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
    };
  }, [setHamburgerWrap]);

  const topNavRef = useRef<HTMLUListElement | null>(null);

  const onGtmClick = () => {
    const data = topNavRef?.current;
    const propDataWithTel = props?.topNavigation?.menuItems?.find(
      (x: NavigationLinkProps) => String(x?.url ?? '').includes('tel:')
    );
    const nodes: HTMLAnchorElement[] = [];
    data?.querySelectorAll('a')?.forEach((item) => {
      if (item?.textContent === propDataWithTel?.text) {
        nodes.push(item);
      }
    });
    nodes && nodes?.length > 0
      ? setDisableGtmApi(false)
      : setDisableGtmApi(true);
  };

  useLayoutEffect(() => {
    const updateBodyPadding = () => {
      const footerAlert = document.querySelector(
        '.alert-primary'
      ) as HTMLElement;
      const footerAlertHeight = footerAlert?.offsetHeight;
      if (appMenuRef.current) {
        appMenuRef.current.style.paddingBottom = `${footerAlertHeight}px`;
      }
    };
    updateBodyPadding();
    const handleResize = () => {
      if (width > Number(breakpoints.lg)) {
        setIsOpen(false);
      }
    };
    const resizeObserver = new ResizeObserver(handleResize);
    if (hamburgerRef.current) {
      resizeObserver.observe(hamburgerRef.current);
    }
    window.addEventListener('resize', updateBodyPadding);
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', updateBodyPadding);
    };
  }, [width]);

  useEffect(() => {
    // checking overflow for marquee
    const checkOverflow = () => {
      if (contentRef.current && parentRef.current) {
        const isOverflow =
          contentRef.current.clientWidth + scrollWidthBuffer >
          parentRef.current.clientWidth;
        setIsOverflowing(isOverflow);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });
    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }
    window.addEventListener('resize', checkOverflow);
    const contentRefCurrent = contentRef.current;

    const alertStringLength = props.topNavigation?.alert?.text?.length || 0;
    const linkCharsTotal = (props?.topNavigation?.floatingLinks || []).reduce(
      (sum, link) => {
        const text = link.text || '';
        return sum + text.length;
      },
      0
    );
    const totalScrollChars = alertStringLength + linkCharsTotal || 0;
    setTopnavScrollTimer(
      Math.floor(totalScrollChars * 0.15) > minScrollTime
        ? Math.floor(totalScrollChars * 0.15)
        : minScrollTime
    );
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkOverflow);
      if (contentRefCurrent) {
        resizeObserver.unobserve(contentRefCurrent);
      }
    };
  }, [isOverflowing]);

  const alertWithFloatinglinks = useCallback(() => {
    return (
      <div
        aria-hidden={isOverflowing}
        className="inline-flex items-center gap-4 scroll-item min-w-0"
      >
        <Alert
          {...props?.topNavigation?.alert}
          varient="default"
          containerClassName="lg:mx-auto"
          alertBodyClassName="inline-block items-center lg:justify-end"
          textClass="t-13 leading-none"
        />
        {props?.topNavigation?.floatingLinks?.map((link, index) => {
          return (
            <NavigationLink
              key={index}
              text={link?.text}
              isDrawerDropdownLink={true}
              textClass="color-ba-primary-red t-15 font-frutiger leading-none"
              url={link?.url}
              target={link?.target}
              ariaLabel={link?.ariaLabel}
              icon={link?.icon}
              iconPosition={link?.iconPosition}
            />
          );
        })}
      </div>
    );
  }, [isOverflowing]);

  return (
    <div className="flex lg:hidden" aria-label="navigation-drawer-container">
      <div className="hamburger-wrap top-0 flex w-full flex-col">
        <div
          className={`${
            isOpen ? 'hidden' : ''
          } item-center flex w-full bg-ba-gray-75`}
          aria-label="alert-with-link"
        >
          <Container
            ref={parentRef}
            fixed
            className={`overflow-hidden ${props?.topNavigation?.floatingLinks ? '' : ''}`}
          >
            {props?.topNavigation?.alert && !isAlertExpired ? (
              <Tooltip
                open={openToolTip}
                onMouseEnter={handleMouseTooltipEnter}
                onMouseLeave={handleMouseTooltipLeave}
                title={`${props?.topNavigation?.alert?.text}`}
                placement="bottom"
                slotProps={{
                  popper: {
                    modifiers: [
                      {
                        name: 'offset',
                        options: {
                          offset: [0, -5]
                        }
                      }
                    ]
                  }
                }}
                aria-label="tool-tip-container"
              >
                <div
                  ref={contentRef}
                  className={`flex whitespace-nowrap min-w-0 w-fit ${isOverflowing ? 'w-full' : 'mx-auto'}`}
                >
                  <div
                    style={
                      {
                        '--alert-timer': topnavScrollTimer + 's'
                      } as React.CSSProperties
                    }
                    className={`w-fit flex gap-2 md:gap-4 items-center min-w-0 ${isOverflowing ? 'marquee relative' : ''}`}
                  >
                    {alertWithFloatinglinks()}
                    {isOverflowing && alertWithFloatinglinks()}
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className="flex justify-center items-center gap-6">
                {props?.topNavigation?.floatingLinks?.map((link, index) => {
                  return (
                    <NavigationLink
                      key={index}
                      text={link?.text}
                      isDrawerDropdownLink={true}
                      textClass="color-ba-primary-red t-15 font-frutiger leading-none"
                      className="py-1"
                      url={link?.url}
                      target={link?.target}
                      ariaLabel={link?.ariaLabel}
                      icon={link?.icon}
                      iconPosition={link?.iconPosition}
                    />
                  );
                })}
              </div>
            )}
          </Container>
        </div>

        <div ref={hamburgerRef} className="flex w-full relative">
          <Container fixed className="mx-auto py-2 lg:py-4">
            <div className="flex items-center justify-between">
              <Button
                id={'logo-button'}
                aria-label={'logo-button-label'}
                variant="text"
                color="secondary"
                className="nav-logo-button p-0"
                disableFocusRipple
                role="button"
                tabIndex={0}
                sx={{
                  padding: 0
                }}
              >
                <Link
                  tabIndex={0}
                  href={props?.primaryNavigation?.imageLink ?? ':'}
                  className="nav-link"
                  aria-live="polite"
                >
                  {logo && (
                    <CmsImage
                      imagelarge={logo}
                      altText={
                        isTransparentNav
                          ? props?.primaryNavigation?.homePageLogo?.altText
                          : props?.primaryNavigation?.commonLogo?.altText
                      }
                      ariaLabel={
                        isTransparentNav
                          ? props?.primaryNavigation?.homePageLogo?.ariaLabel
                          : props?.primaryNavigation?.commonLogo?.ariaLabel
                      }
                      width={160}
                      height={50}
                      className="object-contain object-left"
                    />
                  )}
                </Link>
              </Button>
              <div
                className="flex items-center justify-center gap-5 sm:gap-8"
                aria-label="navigation-drawer-search"
              >
                {/* hide search button if the current route path is /search */}
                {!pathname?.includes('/search') && (
                  <button
                    onClick={handleSearchClick}
                    id={'search-button'}
                    aria-label={
                      props?.topNavigation?.search?.ariaLabel ?? 'search-label'
                    }
                    className="flex items-center justify-center border-none p-1 lg:text-white"
                  >
                    {isSearchOpen ? (
                      <Icon
                        iconName="close"
                        className={`
                         ${isSearchOpen && isTransparentNav && scrollY <= 150 ? 'text-white' : 'text-primary-black'} svg-icon icon-24 flex items-center justify-center close`}
                      />
                    ) : props?.topNavigation?.search?.icon ? (
                      <CmsImage
                        {...props?.topNavigation?.search?.icon}
                        width={20}
                        height={20}
                        className={`${
                          isOpen ? 'text-primary-black' : 'text-white'
                        } svg-icon icon-24 flex items-center justify-center`}
                      />
                    ) : (
                      <Icon
                        iconName="search"
                        className={` ${isTransparentNav && scrollY <= 150 && !isOpen ? 'text-white' : 'text-primary-black'}  svg-icon icon-24 flex items-center justify-center`}
                      />
                    )}
                  </button>
                )}

                <button
                  id={'drawer-button'}
                  aria-label={'drawer-button'}
                  onClick={() => {
                    toggleDrawerBtn();
                    onGtmClick();
                  }}
                  className={`${disableGtmApi ? 'disable-gtm-evt' : ''} hamburger-button flex items-center justify-center lg:text-white ${
                    isOpen ? 'open' : ''
                  }`}
                >
                  <span className="hidden">hamburger</span>
                  <span
                    className={`bar first ${isTransparentNav && scrollY <= 150 && !isOpen ? 'bg-white' : 'bg-black'}   `}
                  ></span>
                  <span
                    className={`bar second ${isTransparentNav && scrollY <= 150 && !isOpen ? 'bg-white' : 'bg-black'} `}
                  ></span>
                  <span
                    className={`bar third ${isTransparentNav && scrollY <= 150 && !isOpen ? 'bg-white' : 'bg-black'} `}
                  ></span>
                </button>
              </div>
            </div>
            {isSearchOpen && (
              <SiteSearchInputField
                className="search-div lg:top-8 py-4 lg:py-16 z-20  w-full flex justify-center"
                width="100%"
              />
            )}
          </Container>
        </div>
      </div>

      <AnimatePresence mode="wait">
        <LazyMotion features={domAnimation}>
          <m.div
            variants={menuSlide}
            initial="initial"
            animate="enter"
            exit="exit"
            className={`fixed app-menu bg-white max-h-dvh max-w-dvw ${isOpen && !isSearchOpen ? 'h-full w-full' : 'hidden h-0 w-0 scale-0 overflow-hidden will-change-transform'}`}
            data-lenis-prevent
          >
            <div ref={appMenuRef} className={'app-menu-body'}>
              <div className={'app-menu-body-nav'}>
                <Container fixed className="mx-auto py-4 pt-8">
                  {/* Lower Nav links in mobile */}
                  <div
                    {...inspectorProperties({
                      entryId: updatedState?.primaryNavigation?.sys?.id ?? '',
                      fieldId:
                        updatedState?.primaryNavigation?.fields?.internalName?.toString() ??
                        ''
                    })}
                    aria-label="drawer-primary-container"
                  >
                    <ul className="navigation-list item-start mb-4 flex flex-col justify-start gap-4 md:px-0">
                      {props?.primaryNavigation?.menuItems?.map(
                        (item: any, index) => {
                          return (
                            <li
                              key={item?.text}
                              className="nav-item "
                              role="button"
                            >
                              <m.div
                                key={item?.text}
                                className={
                                  'app-menu-link will-change-transform'
                                }
                                custom={item?.text}
                                variants={slide}
                                initial="initial"
                                animate="enter"
                                exit="exit"
                              >
                                {item?.dropdown?.length ? (
                                  <div
                                    key={index}
                                    className={`${
                                      activeIndices.includes(index)
                                        ? 'app-l-submenu--open'
                                        : ''
                                    }  flex flex-col grow`}
                                  >
                                    <button
                                      className={`accordion flex items-center w-full min-w-0 py-2 ${
                                        activeIndices.includes(index)
                                          ? 'active'
                                          : ''
                                      } t-18-21 font-frutiger font-bold grow text-left`}
                                      onClick={() => toggleAccordion(index)}
                                    >
                                      <span>{item?.text}</span>
                                      <Icon
                                        iconName="chevronDownThin"
                                        className={`svg-icon icon-16 flex items-center justify-center ml-auto ${
                                          activeIndices.includes(index)
                                            ? 'transition-transform rotate-180 duration-100'
                                            : ''
                                        }`}
                                      ></Icon>
                                    </button>
                                    {/* inside dropdwon */}
                                    {activeIndices.includes(index) && (
                                      <LazyMotion features={domAnimation}>
                                        <m.div
                                          initial={{
                                            height: '0'
                                          }}
                                          transition={{
                                            duration: 0.2,
                                            ease: 'backInOut'
                                          }}
                                          whileInView={{
                                            height: 'auto'
                                          }}
                                          className="subnav-block will-change-[height]"
                                        >
                                          <div
                                            className={`flex flex-col overflow-hidden min-w-0 border border-solid border-ba-gray-95 rounded px-4 ${
                                              activeIndices.includes(index)
                                                ? 'py-2'
                                                : ''
                                            }`}
                                          >
                                            {item?.dropdown?.map(
                                              (
                                                value: NavigationLinkProps,
                                                key: number
                                              ) => (
                                                <NavigationLink
                                                  {...value}
                                                  key={key}
                                                  isDrawerDropdownLink={true}
                                                  className="t-16-17 py-3  no-underline w-full"
                                                  textClass="t-16-17 font-inter leading-none font-normal "
                                                />
                                              )
                                            )}
                                          </div>
                                        </m.div>
                                      </LazyMotion>
                                    )}
                                  </div>
                                ) : (
                                  <div className="nav-link t-18-21 leading-snug font-frutiger font-bold">
                                    <NavigationLink
                                      {...item}
                                      className="py-2 w-full"
                                      isDrawerDropdownLink={true}
                                      textClass="t-18-21 font-frutiger font-bold"
                                    />
                                  </div>
                                )}
                              </m.div>
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>

                  {/* Upper Nav Links in mobile */}
                  <div
                    {...inspectorProperties({
                      entryId: updatedState?.topNavigation?.sys?.id ?? '',
                      fieldId:
                        updatedState?.topNavigation?.fields?.internalName?.toString() ??
                        ''
                    })}
                    aria-label="drawer-top-container"
                  >
                    <ul
                      ref={topNavRef}
                      className="navigation-list mobile flex flex-col items-start justify-start pt-4 md:px-0"
                    >
                      {props?.topNavigation?.menuItems?.map((item: any) => {
                        return (
                          <li
                            key={item?.text}
                            className="nav-item mr-2 flex items-start no-underline w-full"
                            role="button"
                          >
                            <m.div
                              key={item?.text}
                              className={'app-menu-link will-change-transform'}
                              custom={item?.text}
                              variants={slide}
                              initial="initial"
                              animate="enter"
                              exit="exit"
                            >
                              <div className="my-2 flex items-center justify-start py-2">
                                {item?.text !== 'search icon' && (
                                  <NavigationLink
                                    {...item}
                                    className=" py-2  no-underline"
                                    textClass="t-18-21 font-inter leading-none font-normal "
                                  />
                                )}
                              </div>
                            </m.div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </Container>
              </div>
            </div>
          </m.div>
        </LazyMotion>
      </AnimatePresence>
    </div>
  );
}
export default NavigationDrawer;
