'use client';
import React, { useCallback, useEffect, useRef } from 'react';
import {
  Heading,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { Box } from '@mui/material';
import { useDeviceSize } from '@bayada/hooks';
import ComponentResolver from '../component-resolver/component-resolver';
import { Document } from '@contentful/rich-text-types';
import { CmsImageProps, HeadingValue } from '@bayada/interfaces';
import { breakpoints } from '@bayada/assets';
import AccordionItem from './accordion-item';
import { accordionPanelStyles } from './accordian-styles-constants';
import { MarkDown } from '@bayada/shared/ui-components';

export type AccordionBehavior = 'all open' | 'first open' | 'all closed';
export type AccordionAlignment = 'bottom' | 'right';
export interface AccordionContainerProps {
  internalName?: string;
  heading?: string;
  headingValue?: HeadingValue;
  accordionItems: AccordionItem[];
  theme?: string;
  behavior?: AccordionBehavior;
  contentAlignment?: AccordionAlignment;
  sys: { [key: string]: unknown };
  className?: string;
  backgroundColor?: string;
}

export interface AccordionItem {
  isExpanded?: boolean;
  heading: string;
  internalName?: string;
  icon?: CmsImageProps | null;
  content?: AccordionContent[];
  sys: { [key: string]: unknown };
  id?: string;
}

export interface AccordionContent {
  fields: {
    internalName?: string;
    heading?: string;
    subHeading?: string;
    headingValue?: HeadingValue;
    text: Document;
  };
  sys: { [key: string]: unknown };
}

/* This is a TypeScript React component that implements an accordion functionality. Here is a
breakdown of what the code is doing: */
export const AccordionContainer: React.FC<AccordionContainerProps> = (
  props
) => {
  const [width] = useDeviceSize();
  const detailsRef = useRef<HTMLDivElement>(null);
  const {
    accordionItems,
    theme,
    behavior,
    headingValue = 'h3',
    heading,
    contentAlignment
  } = props || {};

  const [accordions, setAccordionItems] =
    React.useState<AccordionItem[]>(accordionItems);
  // flag to show the accordion as a vertical tab if the alignment property is right and device width > 1200
  const isVertical =
    contentAlignment === 'right' && width >= Number(breakpoints.lg);

  useEffect(() => {
    setAccordionItems(accordionItems);
  }, [accordionItems]);

  // Accordion item onChnage event
  const handleChange = useCallback(
    (item: AccordionItem) => {
      const state = [...accordions];

      if (
        !isVertical &&
        (behavior === 'all open' || behavior === 'all closed')
      ) {
        const updatedState = state?.map((accItem) => {
          if (accItem?.id === item?.id) {
            accItem.isExpanded = !accItem?.isExpanded;
          }
          return accItem;
        });
        setAccordionItems(updatedState);
      } else {
        const updatedState = state?.map((accItem) => {
          if (accItem?.id === item?.id) {
            accItem.isExpanded = true;
          } else {
            accItem.isExpanded = false;
          }
          return accItem;
        });
        setAccordionItems(updatedState);
        setTimeout(() => {
          detailsRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center'
          });
        }, 300);
      }
    },
    [accordions, behavior, isVertical]
  );

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);
  // Accordion-container-heading render function
  const renderAccordionContainerHeading = useCallback(() => {
    return (
      <Heading type={headingValue} className=" mb-4 lg:mb-8 leading-none">
        <span
          dangerouslySetInnerHTML={{ __html: MarkDown(heading as string) }}
        />
      </Heading>
    );
  }, [heading, headingValue]);

  // Accordion-container-details render function
  const renderAccordionDetails = useCallback(
    (contentItem: AccordionContent, index: number) => {
      return (
        <div key={index} className="accordion-content-wrapper w-full min-w-0">
          <ComponentResolver
            className="section-block p0 min-w-0"
            data={contentItem}
          />
        </div>
      );
    },
    []
  );

  // This is a React component that renders an accordion with multiple panels.
  const renderAccordionPanels = useCallback(() => {
    return (
      <div className="w-full accordion-panels">
        {accordions?.map((item, index) => {
          return (
            <AccordionItem
              key={index}
              item={item}
              isVertical={isVertical}
              handleChange={handleChange}
              theme={theme}
            />
          );
        })}
      </div>
    );
  }, [accordions, isVertical, handleChange, theme]);

  // This function is used to render a accordion with vertical tab layout
  const renderVerticalTabLayout = useCallback(() => {
    return (
      <>
        {heading && renderAccordionContainerHeading()}
        <div className="grid w-full grid-cols-12">
          <Box className="col-span-3" sx={accordionPanelStyles}>
            {renderAccordionPanels()}
          </Box>
          <div className="col-span-1"></div>
          <div className="col-span-8">
            <div ref={detailsRef} className="w-full grid gap-6">
              {accordions?.map((accItem, accIndex) => {
                // If the accordion item is expanded, render the accordion details:
                if (accItem?.isExpanded) {
                  return (
                    // eslint-disable-next-line react/jsx-no-useless-fragment
                    <React.Fragment key={accIndex}>
                      {accItem?.content?.map((contentItem, itemIndex) => {
                        // Return the rendered accordion details:
                        return renderAccordionDetails(contentItem, itemIndex);
                      })}
                    </React.Fragment>
                  );
                }
                // Otherwise, return null:
                return null;
              })}
            </div>
          </div>
        </div>
      </>
    );
  }, [
    // Pass in the following props:
    accordions,
    renderAccordionPanels,
    renderAccordionContainerHeading,
    heading,
    renderAccordionDetails
  ]);

  // A function that returns a component based on the value of `isVertical`
  const renderAccordion = useCallback(() => {
    // If `isVertical` is true, render the vertical tab layout component
    if (isVertical) {
      return renderVerticalTabLayout();
    }
    // Otherwise, render the accordion panels component
    return renderAccordionPanels();
  }, [isVertical, renderVerticalTabLayout, renderAccordionPanels]);

  const bg =
    props?.backgroundColor === 'light grey'
      ? 'bg-ba-gray-75'
      : props?.backgroundColor === 'light blue'
        ? 'bg-ba-blue-150'
        : props?.backgroundColor === 'white'
          ? 'bg-white'
          : 'bg-transparent';

  // Accordion-container render function
  const renderAccordionContainer = useCallback(() => {
    return (
      <div className={`accordion-container`}>
        <WrapperContainer
          className="mx-auto flex flex-col py-12"
          wrapper={wrapperContainer}
          containerCoverClass={bg}
        >
          {heading && !isVertical && renderAccordionContainerHeading()}
          {renderAccordion()}
        </WrapperContainer>
      </div>
    );
  }, [
    wrapperContainer,
    theme,
    isVertical,
    heading,
    renderAccordionContainerHeading,
    renderAccordion
  ]);

  return renderAccordionContainer();
};
