/**
 * The function `getBannerBgColor` returns a CSS class based on the provided background color.
 * @param {string} [backgroundColor] - The `getBannerBgColor` function takes an optional
 * `backgroundColor` parameter, which is a string representing the desired background color for a
 * banner. The function will return a CSS class name based on the provided `backgroundColor` value. If
 * no `backgroundColor` is provided, the default background color will be
 * @returns The function `getBannerBgColor` returns a CSS class name based on the provided
 * `backgroundColor` parameter. If the `backgroundColor` is 'grey', it returns 'bg-gray-300'. If the
 * `backgroundColor` is 'none', it returns an empty string. Otherwise, it returns the default
 * 'blue-gradient' class name.
 */
export const getBannerBgColor = (backgroundColor?: string) => {
  let bgCss = 'blue-gradient';
  if (backgroundColor === 'grey') {
    bgCss = 'bg-gray-300';
  } else if (backgroundColor === 'none') {
    bgCss = '';
  }
  return bgCss;
};

/**
 * The function `getBannerTextColor` returns a CSS class based on the input `textColor`.
 * @param {string} [textColor] - The `getBannerTextColor` function takes an optional parameter
 * `textColor`, which is a string representing the color of the banner text. If the `textColor` is
 * 'black', the function will return the class name 'text-primary-black' for the banner text color.
 * Otherwise, it will default
 * @returns The function `getBannerTextColor` returns the CSS class name based on the `textColor`
 * parameter. If the `textColor` is 'black', it returns 'text-primary-black', otherwise it returns
 * '!text-white'.
 */
export const getBannerTextColor = (textColor?: string) => {
  let textClass = '!text-white text-shadow';
  if (textColor === 'black') {
    textClass = 'text-primary-black';
  }
  return textClass;
};
