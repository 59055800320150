/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { Container } from '@mui/material';
import { BannerProps } from '../banner';
import React from 'react';
import {
  CmsImage,
  CtaButton,
  CtaButtonProps,
  DonateCta,
  DonateCtaProps,
  Heading
} from '@bayada/shared/ui-components';
import { getBannerBgColor, getBannerTextColor } from '../banner-helper';
import FindCare, { FindCareProps } from '../../find-care/find-care';
import { MarkDown } from '@bayada/shared/ui-components';

/**
 * The `CenterAlignedContentBanner` function in TypeScript React renders a banner with center-aligned
 * content, including heading, subheading, text, image, call-to-action button, and customizable styles.
 * @param {BannerProps} props - The `CenterAlignedContentBanner` component takes the following props:
 */
export const CenterAlignedContentBanner = (props: BannerProps) => {
  const {
    heading,
    subHeading,
    text,
    headingValue,
    image,
    cta,
    backgroundColor,
    textColor,
    internalName,
    findCareComponent,
    findCareComponentAlignment
  } = props || {};
  const bgCss = getBannerBgColor(backgroundColor);
  const textClass = getBannerTextColor(textColor);
  return (
    <section
      className={`banner-section banner-with-center-content ${bgCss} ${findCareComponentAlignment === 'bottom center edge' && findCareComponent ? `py-[2.5rem] sm:pt-[3rem] sm:pb-[5rem] md:pt-[5rem] md:pb-[3.5rem]` : 'py-10 sm:py-12 md:py-20'}`}
      role="group"
      aria-label={`${internalName || 'Banner with Center aligned content'}`}
    >
      <Container fixed className={`mx-auto`}>
        <div
          className={`grid w-full grid-cols-12 text-center ${findCareComponentAlignment === 'bottom center edge' && findCareComponent && findCareComponent && `mb-10`}`}
        >
          <div className="lg:col-span-8 lg:col-start-3 sm:col-span-10 sm:col-start-2 col-span-12">
            <div className="flex flex-col justify-center">
              {image && (
                <div
                  className="banner-icon mb-4 flex justify-center align-center"
                  role="img"
                  aria-label="Banner Icon"
                >
                  <figure className="overflow-hidden">
                    <CmsImage
                      className="object-contain"
                      {...image}
                      width={40}
                      height={40}
                      priority={true}
                    />
                  </figure>
                </div>
              )}
              {heading && (
                <Heading
                  type={headingValue}
                  className={`${subHeading || text || cta ? 'mb-4' : 'mb-0'} ${textClass}`}
                  ariaLevel="1"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <h5
                  className={`t-18-19 ${text || cta ? 'mb-4' : 'mb-0'} font-normal span-typeface-medium font-inter opacity-90 ${textClass}`}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </h5>
              )}
              {text && (
                <p
                  className={`t-18-19 span-typeface-medium font-inter font-normal opacity-90 ${textClass} ${cta ? 'mb-6' : 'mb-0'}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
                </p>
              )}
              <div className="cta-holder flex gap-4 lg:gap-6  flex-col xs:justify-center xs:flex-row xs:items-center xs:flex-wrap empty:hidden ">
                {cta &&
                  cta?.length &&
                  cta?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="banner-cta-wrapper cta-wrapper mt-4 text-[0px] w-full xs:w-auto"
                    >
                      {String(item?.sys?.contentType?.sys?.id).toLowerCase() ===
                      'donationscta' ? (
                        <DonateCta {...(item as DonateCtaProps)} />
                      ) : (
                        <CtaButton
                          {...(item as CtaButtonProps)}
                          className="mx-auto w-full xs:w-auto"
                          sx={
                            item.buttonType === 'outlined'
                              ? {
                                  border: '1px solid var(--white)',
                                  backgroundColor:
                                    'rgba(var(--primary-black),0.1)',
                                  color: 'var(--white)',
                                  '&:hover': {
                                    backgroundColor: 'var(--white)',
                                    color: 'var(--ba-primary-black)'
                                  }
                                }
                              : {}
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {findCareComponent && (
          <div
            className={`${findCareComponentAlignment === 'bottom center edge' && findCareComponent && `relative z-10 bottom-0 justify-center flex mb-0 sm:mb-[-5.844rem]`} ${findCareComponentAlignment === 'center' ? 'justify-center flex' : `float-${findCareComponentAlignment}`} mt-6 w-full`}
          >
            <FindCare
              {...({
                ...findCareComponent,
                alignment: findCareComponentAlignment,
                isBanner: true
              } as FindCareProps)}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default CenterAlignedContentBanner;
