// Carousel wrapper is used to set the rightedge margin as 0 in some conditions based on the csMargin flag (true or false)
'use client';
import React from 'react';

export interface CarouselWrapperProps {
  children: React.ReactNode;
  cssClass?: string;
}

export const CarouselWrapper = (props: CarouselWrapperProps) => {
  const { children, cssClass } = props || {};

  return (
    <div className={`${cssClass ? cssClass : ''} ba-slider-wrapper`}>
      {children}
    </div>
  );
};

export default CarouselWrapper;
