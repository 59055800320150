/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import React from 'react';
import { Container } from '@mui/material';
import { BannerProps } from '../banner';
import {
  CmsImage,
  CtaButton,
  CtaButtonProps,
  DonateCta,
  DonateCtaProps,
  Heading
} from '@bayada/shared/ui-components';
import { getBannerBgColor, getBannerTextColor } from '../banner-helper';
import FindCare, { FindCareProps } from '../../find-care/find-care';
import { MarkDown } from '@bayada/shared/ui-components';

/**
 * The `RightAlignedContentBanner` component in TypeScript React renders a banner with right-aligned
 * content including heading, subheading, text, image, call-to-action button, and customizable styles.
 * @param {BannerProps} props - The `RightAlignedContentBanner` component takes the following props:
 */
export const RightAlignedContentBanner = (props: BannerProps) => {
  const {
    heading,
    subHeading,
    text,
    headingValue,
    image,
    cta,
    backgroundColor,
    textColor,
    findCareComponent,
    internalName,
    findCareComponentAlignment
  } = props || {};
  const bgCss = getBannerBgColor(backgroundColor);
  const textClass = getBannerTextColor(textColor);

  return (
    <section
      className={`banner-section banner-with-rightside-content ${bgCss} ${findCareComponentAlignment === 'bottom center edge' && findCareComponent ? `py-10 sm:py-12 md:pt-20 md:pb-10 lg:pt-20` : 'py-10 sm:py-12 md:py-20'} `}
      role="group"
      aria-label={`${internalName || 'Banner with right aligned content'}`}
    >
      <Container fixed className={`mx-auto`}>
        <div
          className={`grid w-full grid-cols-1 gap-4 lg:gap-0 lg:grid-cols-12  ${findCareComponentAlignment === 'bottom center edge' && findCareComponent && findCareComponent && `mb-10`}`}
        >
          {image && (
            <div className="grid grid-cols-1 col-span-1 lg:col-span-3">
              <figure
                className="w-full flex justify-end items-center"
                role="img"
                aria-label="Banner Right Side Image"
              >
                <CmsImage
                  {...image}
                  className="object-contain object-right lg:object-center w-full flex justify-start lg:items-center lg:mx-auto"
                  width={290}
                  height={218}
                  priority={true}
                />
              </figure>
            </div>
          )}
          <div
            className={`grid grid-cols-1 col-span-1 pr-0 w-full ${image ? 'lg:col-start-5 lg:col-span-8' : 'lg:col-start-1 lg:col-span-12'}`}
            aria-label="banner-contents-right"
          >
            <div className="flex flex-col justify-center text-right origin-right">
              {heading && (
                <Heading
                  type={headingValue}
                  className={`${subHeading || text || cta ? 'mb-4' : 'mb-0'} ${textClass}`}
                  ariaLevel="1"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <p
                  className={`t-16-17 ${text || cta ? 'mb-4' : 'mb-0'} font-normal span-typeface-medium font-inter opacity-90 ${textClass}`}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </p>
              )}

              {text && (
                <p
                  className={`t-14-15 span-typeface-medium font-inter font-normal opacity-90 ${cta ? 'mb-6' : 'mb-0'} ${textClass}`}
                >
                  <span dangerouslySetInnerHTML={{ __html: MarkDown(text) }} />
                </p>
              )}
              <div className="cta-holder flex gap-4 lg:gap-6  flex-col xs:flex-row xs:justify-end xs:items-center xs:flex-wrap empty:hidden">
                {cta &&
                  cta?.length &&
                  cta?.map((item: any, index: number) => (
                    <div
                      key={index}
                      className="banner-cta-wrapper cta-wrapper text-[0px] w-full xs:w-auto"
                    >
                      {String(item?.sys?.contentType?.sys?.id).toLowerCase() ===
                      'donationscta' ? (
                        <DonateCta {...(item as DonateCtaProps)} />
                      ) : (
                        <CtaButton
                          {...(item as CtaButtonProps)}
                          className="mx-auto w-full xs:w-auto"
                          sx={
                            item.buttonType === 'outlined'
                              ? {
                                  border: '1px solid var(--white)',
                                  backgroundColor:
                                    'rgba(var(--primary-black),0.1)',
                                  color: 'var(--white)',
                                  '&:hover': {
                                    backgroundColor: 'var(--white)',
                                    color: 'var(--ba-primary-black)'
                                  }
                                }
                              : {}
                          }
                        />
                      )}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
        {findCareComponent && (
          <div
            className={`mt-6 ${findCareComponentAlignment === 'bottom center edge' && findCareComponent && `relative z-10 bottom-0 mb-0 sm:mb-[-4.844rem] w-full justify-center flex`} ${findCareComponentAlignment === 'center' ? 'justify-center flex' : `float-${findCareComponentAlignment}`}`}
          >
            {findCareComponent && (
              <FindCare
                {...({
                  ...findCareComponent,
                  alignment: findCareComponentAlignment,
                  isBanner: true
                } as FindCareProps)}
              />
            )}
          </div>
        )}
      </Container>
    </section>
  );
};

export default RightAlignedContentBanner;
