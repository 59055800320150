// Custom style for accordion panels
export const accordionPanelStyles = {
  '.accordion-panels': {
    '.accordion-item-wrapper': {
      marginBottom: 0,
      '.MuiAccordion-root': {
        '.MuiAccordionSummary-root': {
          background: 'none',
          opacity: '1',
          '.MuiAccordionSummary-content': {
            h6: {
              color: 'var(--ba-primary-black)'
            }
          }
        },
        '.MuiAccordionSummary-expandIconWrapper': {
          color: 'rgba(var(--primary-black),0.30)'
        }
      },
      '&.active': {
        '.MuiAccordion-root': {
          '.MuiAccordionSummary-root': {
            '.MuiAccordionSummary-content': {
              h6: {
                color: 'var(--ba-primary-red)'
              }
            }
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            color: 'var(--ba-primary-black)'
          }
        }
      }
    }
  }
};
