'use client';
import React, { useCallback } from 'react';
import { CarouselItem } from '../static-carousel-container';
import { Container } from '@mui/material';
import {
  Carousel,
  CarouselSlide,
  CmsImage,
  CtaButton,
  CtaButtonProps,
  Heading
} from '@bayada/shared/ui-components';
import {
  Alignment,
  BackgroundColor,
  Position,
  HeadingValue
} from '@bayada/interfaces';
import { quickHelpCarouselProperties } from '../carousel-constants';
import { Document } from '@contentful/rich-text-types';
import CarouselWrapper from '../carousel-wrapper';
import { CmsRichText } from '../../cms-rich-text/cms-rich-text';
import { MarkDown } from '@bayada/shared/ui-components';

export interface QuickHelpCarousel {
  heading: string;
  headingValue?: HeadingValue;
  subHeading?: string;
  text?: string;
  alignment?: Alignment;
  backgroundColor?: BackgroundColor;
  items?: CarouselItem[];
  cta?: CtaButtonProps | null;
  ctaPosition?: Omit<Position, 'left' | 'right'>;
  richText?: Document | unknown;
  internalName?: string;
}
/**
This code defines a functional component ServicesCarousel that takes in props of type QuickHelpCarousel.
It dynamically sets the background color based on the backgroundColor prop, creates a ContainerCtaButton
component based on the cta prop, and renders a Carousel with various elements like headings, text, images,
and buttons based on the items prop. Additionally, it renders rich text content using the CmsRichText
component if the richText prop is provided.
 */
const ServicesCarousel = (props: QuickHelpCarousel) => {
  const {
    items,
    heading,
    subHeading,
    alignment,
    backgroundColor,
    headingValue,
    text,
    ctaPosition,
    cta,
    richText
  } = props || {};

  let bg = 'bg-transparent';
  if (backgroundColor === 'light grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  const ContainerCtaButton = useCallback(() => {
    if (cta) {
      return (
        <div
          className={` md:text-${alignment} ${ctaPosition === 'bottom' && 'mt-6'}`}
        >
          <CtaButton {...cta} className="ba-text-primary" />
        </div>
      );
    }
    return null;
  }, [cta, alignment, ctaPosition]);

  return (
    <>
      <div className={`py-10 sm:py-12 md:py-14 overflow-hidden ${bg}`}>
        <Container
          fixed
          className="relative mx-auto"
          aria-label="quick-help-carousel"
        >
          <div className="w-full mx-auto grid-cols-12 grid">
            <div
              className={`text-${alignment} col-span-12 flex flex-col gap-4
              ${alignment == 'center' ? 'lg:col-start-3 lg:col-span-8' : ''}`}
            >
              {heading && (
                <Heading
                  type={headingValue}
                  className={`text-primary-black  font-frutiger  `}
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <h5
                  className="t-18-21 font-frutiger set-frutiger font-bold text-primary-black"
                  aria-label="sub-heading-label"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </h5>
              )}
              {text && (
                <div className={``}>
                  <p
                    className="t-16-19 mx-auto font-normal color-ba-gray-900"
                    aria-label="text-label"
                  >
                    <span
                      dangerouslySetInnerHTML={{ __html: MarkDown(text) }}
                    />
                  </p>
                </div>
              )}
              {ctaPosition === 'top' && <ContainerCtaButton />}
            </div>
          </div>
          <div className="mx-auto mt-10 lg:mt-14  w-full">
            <CarouselWrapper cssClass="overflow-carousel">
              <Carousel {...quickHelpCarouselProperties}>
                {items?.map((data, index: number) => {
                  const cta = data?.cta;
                  const image = data?.image;
                  return (
                    <CarouselSlide key={index} className="pt-[4.375rem]">
                      <div className="px-6 pb-10 bg-ba-gray-75 flex flex-col items-center rounded w-full img-expansion border border-white border-solid shadow-white shadow-md">
                        {image && (
                          <figure className="w-[8.75rem] h-[8.75rem] shrink-0 mb-8 mt-[-4.375rem] overflow-hidden rounded-full">
                            <CmsImage
                              {...image}
                              className="rounded-full w-full h-full d-block object-cover"
                              altText="background"
                              width={140}
                              height={140}
                            />
                            <div className="swiper-lazy-preloader"></div>
                          </figure>
                        )}
                        <div className="flex flex-col items-center text-center w-10/12 h-full">
                          {data?.title && (
                            <Heading
                              type={'h6'}
                              className="t-14 text-primary-shade mb-4 uppercase line-clamp-10 "
                              aria-label="carousel-title"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.title)
                                }}
                              />
                            </Heading>
                          )}
                          {data?.heading && (
                            <Heading
                              type={'h6'}
                              className="t-16-17 mb-1 font-semibold text-primary-black line-clamp-10 "
                              aria-label="carousel-heading"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.heading)
                                }}
                              />
                            </Heading>
                          )}
                          {typeof data?.text === 'string' && data?.text && (
                            <p
                              className="t-13 color-ba-gray-425 mb-8 color-ba-gray-900"
                              aria-label="carousel-text"
                            >
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: MarkDown(data?.text)
                                }}
                              />
                            </p>
                          )}
                          {cta && (
                            <div className="mt-auto" aria-label="carousel-cta">
                              <CtaButton
                                {...cta}
                                className=""
                                sx={{
                                  '.MuiButton-icon': {
                                    width: 20
                                  }
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </CarouselSlide>
                  );
                })}
              </Carousel>
              {ctaPosition === 'bottom' && <ContainerCtaButton />}
            </CarouselWrapper>
          </div>
          {(richText as Document) && (
            <div
              className={`mx-auto mt-8 w-full text-${alignment}`}
              aria-label="rich-text-container"
            >
              <CmsRichText
                value={richText as Document}
                className="t-16-17"
                font="font-inter"
              />
            </div>
          )}
        </Container>
      </div>
    </>
  );
};

export default ServicesCarousel;
