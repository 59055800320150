/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';

import { CmsImage } from '@bayada/shared/ui-components';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useState } from 'react';
import { CmsTabItemProps, TabItemProps } from '../cms-tab';
import ComponentResolver from '../../component-resolver/component-resolver';

/* This code snippet is a TypeScript React component called `CommonTab`. It is a tab component that uses Material-UI Tabs and Tab components to display tabbed content. Here is a breakdown of what the code is doing: */

const CommonTab = (props: CmsTabItemProps) => {
  const [templateValue, setTemplateValue] = useState(0);
  const handleChange = (newValue: number) => {
    setTemplateValue(newValue);
  };

  return (
    <>
      <Tabs
        value={templateValue}
        onChange={(e, value) => handleChange(value)}
        centered={props?.isCentered}
        aria-label="simple tab"
        className={`${props?.isWrapTheme ? 'tabs-no-border' : ''} `}
      >
        {props?.tabItems?.map((item: TabItemProps, i: number) => (
          <Tab
            key={i}
            disableRipple
            icon={
              item?.icon ? (
                <CmsImage
                  {...item?.icon}
                  width={32}
                  height={32}
                  className="mx-1"
                />
              ) : (
                ''
              )
            }
            iconPosition={item?.iconPosition === 'after' ? 'end' : 'start'}
            label={item?.tabLabel}
            className={`${props?.isWrapTheme ? 'tab-no-border' : ''} `}
          />
        ))}
      </Tabs>

      {props?.tabItems?.map((item: TabItemProps, i: number) => {
        let contentPosition = 'item-left';
        switch (item.contentPosition) {
          case 'left':
            contentPosition = 'sm:justify-items-start';
            break;
          case 'right':
            contentPosition = 'sm:justify-items-end';
            break;
          case 'center':
            contentPosition = 'sm:justify-items-center';
            break;
          default:
            contentPosition = 'sm:justify-items-start';
            break;
        }
        return (
          <div
            key={i}
            role="tabpanel"
            hidden={templateValue !== i}
            id={`simple-tabpanel-${i}`}
            aria-label={`tab-panel-${item?.tabLabel ?? `${i}`}`}
            className={`tab-panel grid grid-cols-1 gap-4 flex-1 grow pt-6 lg:pt-10 min-w-0 ${contentPosition}`}
          >
            {item?.content?.map((contentItem: any) => (
              <ComponentResolver
                className="section-block min-w-0"
                key={contentItem?.sys?.id}
                data={contentItem}
              />
            ))}
          </div>
        );
      })}
    </>
  );
};

export default CommonTab;
