/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Carousel,
  CarouselSlide,
  CmsImage
} from '@bayada/shared/ui-components';
import { Link, Stack } from '@mui/material';
import { CombinedData } from './social-media';
import Image from 'next/image';
import { SwiperProps } from 'swiper/react';
import { useAppContext } from 'apps/bayada/context/app-context';

export type SocialMediaProps = { socialMediaItems: CombinedData[] };
export const SocialMediaCarouselProperties: SwiperProps = {
  slidesPerView: 4,
  centeredSlides: false,
  slidesPerGroupSkip: 3,
  grabCursor: true,
  keyboard: {
    enabled: true
  },
  breakpoints: {
    1365: {
      slidesPerView: 4,
      slidesPerGroup: 1
    },
    1200: {
      slidesPerView: 3,
      slidesPerGroup: 1
    },
    991: {
      slidesPerView: 2,
      slidesPerGroup: 1
    },
    769: {
      slidesPerView: 2,
      slidesPerGroup: 1
    },
    575: {
      slidesPerView: 2,
      slidesPerGroup: 1
    },
    0: {
      slidesPerView: 1,
      slidesPerGroup: 1
    }
  },
  scrollbar: { draggable: true },
  navigation: false,
  pagination: false,
  className: '',
  spaceBetween: 24
};

/**
 * This component renders a Carousel of social media posts
 * passed to it via the `socialMediaItems` prop
 */
export const SocialMediaCarousel = (props: SocialMediaProps) => {
  const { socialMediaItems } = props || {};
  const { context } = useAppContext();
  const { userAgent } = context || {};
  return (
    <div className="ba-slider-wrapper overflow-carousel">
      <Carousel className="mySwiper" {...SocialMediaCarouselProperties}>
        {socialMediaItems?.map((item: any, index: number) => (
          <CarouselSlide key={index}>
            <Stack
              className="social-media-slide w-full border border-solid border-ba-gray-95 bg-white rounded-lg"
              direction="column"
              spacing={2}
            >
              <Link
                href={item?.permalink || item?.post_url}
                target="_blank"
                onClick={(e) => {
                  if (userAgent === 'Firefox') {
                    e?.preventDefault();
                    window.open(item?.permalink || item?.post_url, '_blank');
                  }
                }}
              >
                <div className="flex p-3 gap-2">
                  <div className="flex gap-2 items-center grow">
                    {item?.profilePicture && (
                      <div
                        className="shrink-0 w-7 h-7 overflow-hidden rounded-full"
                        aria-label="profile-pic-container"
                      >
                        <Image
                          src={item?.profilePicture || ''}
                          alt={'profile image'}
                          width={28}
                          height={28}
                        />
                      </div>
                    )}
                    <div className="min-w-0">
                      <h5
                        className="t-13 font-semibold text-primary-black"
                        aria-label="user-name-container"
                      >
                        {item?.username || item?.pageName}
                      </h5>
                    </div>
                  </div>
                  <div
                    className="ml-auto shrink-0 social-media-icon"
                    aria-label="platform-icon"
                  >
                    {item?.icon && (
                      <CmsImage {...item?.icon} width={24} height={24} />
                    )}
                  </div>
                </div>
                <div className="!mt-0 h-0 overflow-hidden relative pt-[75%]">
                  <div
                    className="absolute top-0 left-0 w-full h-full social-media-image"
                    aria-label="media-container"
                  >
                    <Image
                      src={
                        item?.media_type === 'VIDEO'
                          ? item?.thumbnail_url
                          : item?.media_url || item?.full_picture
                      }
                      alt={item?.caption || item?.message}
                      width={200}
                      height={200}
                      className="object-cover !h-full"
                      style={{ width: '100%', height: 'auto' }}
                    />
                    {(item?.media_type === 'VIDEO' ||
                      item?.attachments?.data[0].type === 'video_inline') && (
                      <div
                        className="absolute inset-0 flex items-center justify-center"
                        aria-label="play-icon"
                      >
                        <Image
                          src="/temp/Play button.svg"
                          alt="play button"
                          height={72}
                          width={72}
                        />
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex items-center py-4 px-3 !mt-0"></div>
              </Link>
            </Stack>
          </CarouselSlide>
        ))}
      </Carousel>
    </div>
  );
};
