/* eslint-disable @typescript-eslint/no-explicit-any */
import { EntryCollection, EntrySkeletonType } from 'contentful';
import { homePageUrl } from '../constants';
import { ServiceProps } from '@bayada/interfaces';

// Export a function named "slugToArray" which is a function that takes a "slug" parameter and returns an array
export const slugToArray = (slug: string) => {
  if (!slug) {
    return [];
  }
  const slugArray =
    slug !== homePageUrl ? slug?.toLowerCase()?.split('/') : [homePageUrl];
  // Return the slugArray
  return slugArray;
};

// Export a function named constructPageQuery that takes a slug as an argument and returns query object.
export const constructPageQuery = (slug: string) => {
  const slugArray = slugToArray(slug);
  const query: { [key: string]: string | number | string[] | boolean } = {
    content_type: 'page',
    'fields.slug[in]': slug,
    select: ['fields.slug', 'fields.parentPage'],
    order: 'sys.createdAt'
  };
  if (slugArray?.length > 1) {
    query['fields.slug[in]'] = slugArray;
    query['fields.parentPage.sys.contentType.sys.id'] = 'page';
    query['fields.parentPage[exists]'] = true;
  }
  // Return the query object
  return query;
};

// Export a constant named `isPageDataValidAgainstSlug` that is a function that takes two parameters, `slug` and `response` and returns a boolean value.
export const isPageDataValidAgainstSlug = (
  slug: string,
  response: EntryCollection<EntrySkeletonType, undefined, string>
) => {
  const slugArray = slugToArray(slug);
  const slugsAgainstData = new Set<string>([]);
  slugArray?.map(() => {
    response?.items?.forEach((item) => {
      const parentSlug = (item?.fields?.parentPage as any)?.fields?.slug;
      const selfSlug = item?.fields?.slug as string;
      if (parentSlug && parentSlug !== homePageUrl) {
        slugsAgainstData.add(parentSlug).add(selfSlug);
      } else {
        slugsAgainstData.add(selfSlug);
      }
    });
  });
  const slugArrayFromSet = Array.from(slugsAgainstData).join('/');
  console.log(
    slugArrayFromSet === slug,
    ` ::: ${slug} isPageDataValidAgainstSlug`
  );
  // Return a boolean value based on whether the slugs in the Set match the slug string
  return slugArrayFromSet === slug ? true : false;
};

export const removeSpecialCharsAndToLower = (str: string) => {
  return str
    ?.trim()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-')
    ?.replace(/[^\w\s]/gi, '');
};

export const replaceWithOfficeData = (data?: string | null, office?: any) => {
  if (data && office) {
    return data
      ?.replace('{{mailingAddressCity}}', office?.mailingAddressCity)
      ?.replace('{{mailingAddressState}}', office?.mailingAddressState)
      ?.replace('{{name}}', office?.name)
      ?.replace('{{jvName}}', office?.jvName)
      ?.replace('{{phone}}', office?.phone);
  }
};

/**
 * Generates an array of service objects based on the provided office services and service list.
 *
 * The function filters and processes the services associated with the given office object to produce
 * a structured array of service details. It avoids duplicates and highlights the current service if specified.
 *
 * @param office - An object representing the office, which includes a `services` property that is an array of service identifiers (strings).
 * @param serviceList - An optional array of service objects. Each object should have properties `shortName`, `fullName`, `icon`, and `masterDataId`.
 *                      The `shortName` is used to match with the office's service identifiers.
 * @param currentService - An optional string representing the name of the current service. This will be used to flag the service as the current one in the result array.
 *
 * @returns An array of service objects. Each object contains:
 *   - `fullName`: The full name of the service.
 *   - `icon`: The icon associated with the service.
 *   - `isCurrentService`: A boolean indicating if the service's `fullName` matches the `currentService` (ignoring case and removing non-word characters).
 *   - `masterDataId`: The unique identifier of the service.
 *
 * The function ensures that duplicate services (based on `masterDataId`) are not included in the resulting array.
 */
export const getServiceArray = (
  office: any,
  serviceList?: ServiceProps[] | null,
  currentService?: string
) => {
  const servArr: any[] = [];
  office?.services?.forEach((ser: string) => {
    const reqItem = serviceList?.find((el: ServiceProps) => {
      const shortName = el?.shortName?.replace(/\s+/g, '')?.toLowerCase();
      if (ser && shortName) {
        return ser
          ?.replace(/\s+/g, '')
          ?.toLowerCase()
          .includes(shortName?.toLowerCase());
      }
      return false;
    });
    const duplicateServ = servArr?.some(
      (item) => item.masterDataId === reqItem?.masterDataId
    );
    if (reqItem && !duplicateServ) {
      servArr?.push({
        fullName: reqItem?.fullName,
        icon: reqItem?.icon,
        isCurrentService: currentService
          ? reqItem?.fullName?.replace(/[^\w\s]/g, ' ')?.toLowerCase() ===
            currentService?.toLowerCase()
          : false,
        masterDataId: reqItem?.masterDataId
      });
    }
  });
  return servArr;
};

/**
 * Generates a URL-friendly slug based on the provided service name.
 *
 * This function converts a service name into a slug suitable for use in a URL. It transforms the service name to lowercase, replaces spaces with hyphens, and replaces slashes with ampersands to ensure the slug is valid and readable in URLs.
 *
 * @param service - An optional string representing the service name to be converted into a slug. If provided, it will be processed to create the slug.
 *
 * @returns A string representing the URL-friendly slug derived from the service name. The slug is formatted as follows:
 *   - Converted to lowercase.
 *   - Spaces replaced with hyphens ('-').
 *   - Slashes ('/') replaced with ampersands ('&').
 *
 * If the `service` parameter is not provided, the function will return `undefined`.
 */
export const getServicePageSlug = (service?: string) => {
  if (service) {
    const slug = `${service
      ?.toLowerCase()
      ?.replace(/\s+/g, '-')
      ?.replace(/\//g, '&')}`;
    return slug;
  }
};
