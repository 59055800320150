/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import { useRef } from 'react';
import { useHubspotForm } from 'next-hubspot';
import {
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { portalId } from 'apps/bayada/constants';

export type HubSpotFormProps = {
  formId: string;
  customClass?: string;
  id: number | string;
  prefillFields?: {
    fieldName: string;
    value: string;
    isHidden?: boolean;
  }[];
};

/*
This component provides a wrapper around a HubSpot form, handling its initialization, loading, 
and error states, and providing custom styling and behavior for form fields.
CmsHubSpotForm is defined as a functional component that accepts HubSpotFormProps.
Inside the component, it extracts the portalId from environment variables, providing fallbacks if they're not defined.
It initializes a ref (formSectionRef) to a div element, which will contain the HubSpot form.
It utilizes the useHubspotForm hook to load and handle the HubSpot form.
Within the hook's onFormReady callback, 
it sets up event listeners for various types of form fields (input, textarea, select) to handle focus and blur events 
and apply CSS classes accordingly.
The component conditionally renders based on whether the form has been successfully loaded 
and created.If so, it renders the form container div otherwise, it displays an error message.
*/

export const CmsHubSpotForm: React.FC<HubSpotFormProps> = (
  props: HubSpotFormProps
) => {
  const { formId, id, customClass, prefillFields = [] } = props || {};
  const formSectionRef = useRef<HTMLDivElement | null>(null);
  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);
  const { loaded, error, formCreated } = useHubspotForm({
    portalId: portalId?.toString() ?? '',
    formId: formId,
    target: `#${id}`,
    cssClass: `${customClass} hubspot-wrapper dark-inputs`,
    // onFormSubmit: () => {},
    onFormReady($form) {
      if ($form) {
        prefillFields?.forEach((field) => {
          if (field && field.fieldName) {
            const formField = field?.isHidden
              ? $form?.querySelector(
                  `input[name="${field.fieldName}"][type="hidden"]`
                )
              : $form?.querySelector(`input[name="${field.fieldName}"]`);
            formField?.setAttribute('value', field.value);
          }
        });
      }
    }
  });

  if (loaded && formCreated) {
    return (
      <div className="flex flex-col items-center">
        <WrapperContainer
          className="mx-auto flex flex-col py-12 md:py-14"
          wrapper={wrapperContainer}
        >
          <div
            className="flex w-full hubspot-form-wrapper"
            id={`${id}`}
            ref={formSectionRef}
          />
        </WrapperContainer>
      </div>
    );
  } else {
    return error ? (
      <>
        <WrapperContainer
          className="mx-auto flex flex-col py-12 md:py-14"
          wrapper={wrapperContainer}
        >
          <h2
            className="t-33-46 mb-4 font-frutiger font-bold"
            aria-label="error-container"
          >
            Error
          </h2>
        </WrapperContainer>
      </>
    ) : (
      <>
        <WrapperContainer
          className="mx-auto flex flex-col py-12 md:py-14"
          wrapper={wrapperContainer}
        >
          <div
            className="flex w-full flex-col items-center justify-center h-full min-h-64 relative p-10"
            aria-label="loading-container"
          >
            <div className="loader"></div>
            <h2 className="t-33-46 mb-4 font-frutiger font-bold mt-8">
              Loading
            </h2>
          </div>
        </WrapperContainer>
      </>
    );
  }
};
