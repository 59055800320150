/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Alert,
  AlertProps,
  CmsImage,
  CtaButtonProps,
  Icon
} from '@bayada/shared/ui-components';
import { Container, Tooltip } from '@mui/material';
import NavigationLink, {
  NavigationLinkProps
} from '../navigation-link/navigation-link';
import { useEffect, useRef, useState } from 'react';
import { SiteSearchInputField } from '../navigation-header/site-search-input/site-search-input';
import { usePathname } from 'next/navigation';

export type TopNavigationProps = {
  alert?: AlertProps | null;
  menuItems?: NavigationLinkProps[];
  search?: CtaButtonProps | null;
  sysId?: string; //for checking api
  searchButtonVisible?: boolean;
  floatingLinks?: NavigationLinkProps[];
};

/**
 * This TypeScript React code defines a TopNavigation component with props for alert, menu items,
 * search functionality, and system ID checking.
 * @property {AlertProps | null} alert - The `alert` property in the `TopNavigationProps` type is used
 * to pass props to the `Alert` component. The `Alert` component displays a message to the user,
 * typically used for showing notifications or important information. The `AlertProps` type defines the
 * props that can be passed to
 * @property {NavigationLinkProps[]} menuItems - The `menuItems` property in the `TopNavigationProps`
 * type is an array of objects with the shape of `NavigationLinkProps`. Each object represents a menu
 * item that can be displayed in the top navigation bar. The `menuItems` array can contain multiple
 * menu items, and each menu item
 * @property search - The `search` property in the `TopNavigationProps` type is an object that contains
 * the following fields:
 * @property {string} sysId - The `sysId` property in the `TopNavigationProps` type is used for
 * checking the API. It seems like it is intended to be a unique identifier or system ID that can be
 * used for API requests or validations within the component or application. This property can help in
 * identifying or referencing specific data
 * @property {boolean} searchButtonVisible - The `searchButtonVisible` property in the
 * `TopNavigationProps` type is a boolean that determines whether the search button should be visible
 * in the top navigation bar. If `searchButtonVisible` is set to `true`, the search button will be
 * displayed, allowing users to click on it to open
 */

export const TopNavigation: React.FC<TopNavigationProps> = (
  props: TopNavigationProps
) => {
  const { alert, menuItems, searchButtonVisible, search } = props || {};
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const isSearchButtonVisible = searchButtonVisible;
  const pathname = usePathname();
  const linkWrapRef = useRef<HTMLDivElement>(null);
  const parentRef = useRef<HTMLDivElement>(null);
  const alertRef = useRef<HTMLDivElement>(null);
  const scrollWidthBuffer = 30;
  const minScrollTime = 3;
  const [scrollTimer, setScrollTimer] = useState(0);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [openToolTip, setOpenToolTip] = useState(false);

  const handleSearchClick = () => {
    setIsSearchOpen(!isSearchOpen);
  };

  const handleMouseTooltipEnter = () => {
    if (isOverflowing) {
      setOpenToolTip(true);
    }
  };

  const handleMouseTooltipLeave = () => {
    setOpenToolTip(false);
  };

  useEffect(() => {
    // checking overflow for marquee
    const checkOverflow = () => {
      if (linkWrapRef.current && parentRef.current && alertRef.current) {
        const isOverflow =
          linkWrapRef.current.clientWidth +
            alertRef.current.clientWidth +
            scrollWidthBuffer >
          parentRef.current.clientWidth;
        setIsOverflowing(isOverflow);
        console.log('ISOVERFLOW', isOverflow);
      }
    };
    const resizeObserver = new ResizeObserver(() => {
      checkOverflow();
    });
    if (linkWrapRef.current) {
      resizeObserver.observe(linkWrapRef.current);
    }
    if (alertRef.current) {
      resizeObserver.observe(alertRef.current);
    }
    window.addEventListener('resize', checkOverflow);
    const linkWrapRefCurrent = linkWrapRef.current;
    const alertRefCurrent = alertRef.current;
    const alertStringLength = props?.alert?.text?.length || 0;
    const linkCharsTotal = (props?.floatingLinks || []).reduce((sum, link) => {
      const text = link.text || '';
      return sum + text.length;
    }, 0);
    const totalScrollChars = alertStringLength + linkCharsTotal || 0;
    setScrollTimer(
      Math.floor(totalScrollChars * 0.15) > minScrollTime
        ? Math.floor(totalScrollChars * 0.15)
        : minScrollTime
    );
    return () => {
      window.removeEventListener('resize', checkOverflow);
      if (linkWrapRefCurrent) {
        resizeObserver.unobserve(linkWrapRefCurrent);
      }
      if (alertRefCurrent) {
        resizeObserver.unobserve(alertRefCurrent);
      }
    };
  }, [isOverflowing]);

  return (
    <div className="top-bar hidden items-center bg-ba-gray-75 lg:flex flex-col relative">
      <Container
        fixed
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
        ref={parentRef}
      >
        <div
          className={`flex justify-between py-2 items-end gap-4 ${isOverflowing ? 'overflow-x-hidden' : ''}`}
        >
          <div
            ref={linkWrapRef}
            className={`top-bar-left ${alert && 'max-w-[80%] shrink-0'}`}
          >
            <ul className="navigation-list flex items-center gap-6 custom-scrollbar">
              {/* hide search button based on isSearchButtonVisible flag and if the current route path is /search */}
              {!isSearchButtonVisible && !pathname?.includes('/search') && (
                <li className="flex items-center">
                  <button
                    aria-label={search?.ariaLabel ?? 'search-label'}
                    onClick={handleSearchClick}
                    className="gap-1 t-13 font-frutiger leading-none no-underline inline-flex items-end"
                  >
                    {search?.iconPosition === 'before text' &&
                      (search?.buttonText ?? 'Search')}
                    {isSearchOpen ? (
                      <Icon
                        iconName="close"
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    ) : search?.icon ? (
                      <CmsImage
                        {...search?.icon}
                        width={20}
                        height={20}
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    ) : (
                      <Icon
                        iconName="search"
                        className="svg-icon icon-20 flex items-center justify-center"
                      />
                    )}
                    {search?.iconPosition !== 'before text' &&
                      (search?.buttonText ?? 'Search')}
                  </button>
                </li>
              )}
              {menuItems?.map((item) => {
                return (
                  item?.text && (
                    <li
                      key={item?.text}
                      className="nav-item flex items-center no-underline !m-0"
                    >
                      {item?.text !== 'search icon' && (
                        <NavigationLink
                          {...item}
                          className="t-13  font-frutiger leading-none no-underline"
                          textClass="font-frutiger"
                        />
                      )}
                    </li>
                  )
                );
              })}
            </ul>
          </div>
          {alert && (
            <Tooltip
              open={openToolTip}
              onMouseEnter={handleMouseTooltipEnter}
              onMouseLeave={handleMouseTooltipLeave}
              title={`${props?.alert?.text}`}
              placement="bottom-start"
              slotProps={{
                popper: {
                  modifiers: [
                    {
                      name: 'offset',
                      options: {
                        offset: [0, -5]
                      }
                    }
                  ]
                }
              }}
              aria-label="tool-tip-container"
            >
              <div
                className="top-bar-right text-left lg:pr-1 lg:text-right custom-scrollbar"
                aria-label="alert-with-link"
              >
                <div className={`min-w-0 flex whitespace-nowrap w-fit`}>
                  <div
                    style={
                      {
                        '--alert-timer': scrollTimer + 's'
                      } as React.CSSProperties
                    }
                    className={`w-fit flex gap-4 items-center min-w-0 ${isOverflowing ? 'marquee relative' : ''}`}
                  >
                    <div ref={alertRef} className="min-w-0">
                      <Alert
                        {...alert}
                        varient={'default'}
                        containerClassName="lg:ml-auto"
                        alertBodyClassName={`inline-block items-center lg:inline cursor-default ${isOverflowing ? 'scroll-item' : ''}`}
                        textClass="t-13"
                      />
                    </div>
                    {isOverflowing && (
                      <Alert
                        aria-hidden={isOverflowing}
                        {...alert}
                        varient={'default'}
                        containerClassName="lg:ml-auto"
                        alertBodyClassName={`inline-block items-center lg:inline cursor-default ${isOverflowing ? 'scroll-item' : ''}`}
                        textClass="t-13"
                      />
                    )}
                  </div>
                </div>
              </div>
            </Tooltip>
          )}
        </div>
      </Container>
      {isSearchOpen && (
        <SiteSearchInputField
          width="80%"
          className="search-div lg:p-16 z-20 absolute top-9 left-0 w-full bg-white flex justify-center"
          handleVisibilityOnSubmit={(flag) => {
            setIsSearchOpen(false);
          }}
        />
      )}
    </div>
  );
};

export default TopNavigation;
