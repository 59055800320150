/* eslint-disable @typescript-eslint/no-explicit-any */
'use client';
import ComponentResolver from '../component-resolver/component-resolver';
import {
  DisableAnimationsMobile,
  Heading,
  WrapperContainer,
  getWrapperProps
} from '@bayada/shared/ui-components';
import { Alignment, HeadingValue } from '@bayada/interfaces';
import { useCallback } from 'react';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import { MarkDown } from '@bayada/shared/ui-components';

export type ItemItemHeadingProps = {
  alignment: string;
  heading: string;
  headingValue: HeadingValue;
  internalName: string;
  backgroundColor: string;
  subHeading: string;
  verticalSeparator: string;
  itemLeft: any[];
  itemRight: any[];
  sysId: string;
  itemLeftAlignment?: Alignment;
  itemRightAlignment?: Alignment;
};

/* This code snippet defines a React functional component called `ItemItemHeading` that takes in props
of type `ItemItemHeadingProps`. The component renders a structured layout with headings,
subHeadings, and dynamic items displayed in left and right columns. */
export const ItemItemHeading: React.FC<ItemItemHeadingProps> = (
  props: ItemItemHeadingProps
) => {
  const {
    alignment = 'center',
    heading,
    headingValue = 'h2',
    backgroundColor = '',
    subHeading,
    verticalSeparator,
    itemLeft,
    itemRight,
    itemLeftAlignment = 'left',
    itemRightAlignment = 'left'
  } = props || {};

  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  let leftAlignmentClass = 'justify-items-start';
  switch (itemLeftAlignment) {
    case 'right':
      leftAlignmentClass = 'justify-items-end';
      break;
    case 'left':
      leftAlignmentClass = 'justify-items-start';
      break;
    case 'center':
      leftAlignmentClass = 'justify-items-center';
      break;
  }

  let rightAlignmentClass = 'justify-items-start';
  switch (itemRightAlignment) {
    case 'right':
      rightAlignmentClass = 'justify-items-end';
      break;
    case 'left':
      rightAlignmentClass = 'justify-items-start';
      break;
    case 'center':
      rightAlignmentClass = 'justify-items-center';
      break;
  }

  let headAlignmentClass = 'items-center text-center';
  switch (alignment) {
    case 'right':
      headAlignmentClass = 'items-end text-right';
      break;
    case 'left':
      headAlignmentClass = 'items-start';
      break;
    case 'center':
      headAlignmentClass = 'items-center text-center';
      break;
  }

  let bg = 'bg-transparent';
  if (backgroundColor === 'grey') {
    bg = 'bg-ba-gray-75';
  } else if (backgroundColor === 'light blue') {
    bg = 'bg-ba-blue-150';
  } else if (backgroundColor === 'white') {
    bg = 'bg-white';
  }

  /* The `renderItemComponent` function is a callback function created using the `useCallback` hook in
React. It takes an optional parameter `items` of type `any`. */
  const renderItemComponent = useCallback((items?: any) => {
    const components = items?.map((item: any, index: number) => (
      <ComponentResolver
        className="section-block min-w-0"
        data={item}
        key={index}
      />
    ));
    if (!items) {
      return <></>;
    }
    return components;
  }, []);

  return (
    <div
      className={`item-item-head flex flex-col`}
      role="region"
      aria-label="item-item-heading"
    >
      <WrapperContainer
        wrapper={wrapperContainer}
        containerCoverClass={`flex flex-col ${bg}`}
        className="relative mx-auto flex flex-col py-10 sm:py-12 md:py-14 min-w-0 item-item-head-container"
      >
        {(heading || subHeading) && (
          <div
            className={`flex flex-col min-w-0 mb-8 ${headAlignmentClass}`}
            role="region"
            aria-label="item-heading-container"
          >
            <div className={`flex w-full flex-col gap-6 lg:w-3/4 xl:w-1/2 `}>
              {heading && (
                <Heading className="mb-2" type={headingValue} ariaLevel="1">
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(heading) }}
                  />
                </Heading>
              )}
              {subHeading && (
                <Heading className="mb-4" type={'h5'} ariaLevel="2">
                  <span
                    dangerouslySetInnerHTML={{ __html: MarkDown(subHeading) }}
                  />
                </Heading>
              )}
            </div>
          </div>
        )}
        <div
          className={`min-w-0 grid grid-cols-1 lg:grid-cols-12 gap-6
          ${verticalSeparator === 'no' ? 'lg:gap-20' : 'lg:gap-0'} item-item-head-grid`}
        >
          <div
            className={`grid ${verticalSeparator === 'no' ? 'lg:col-span-6' : 'lg:col-span-5'} content-start gap-4 empty:hidden min-w-0 ${leftAlignmentClass} item-item-head-left`}
            role="region"
            aria-label="item-contents-container-left"
          >
            {renderItemComponent(itemLeft)}
          </div>

          {verticalSeparator === 'no' ? null : (
            <LazyMotion features={domAnimation}>
              <DisableAnimationsMobile>
                <m.div
                  initial={{
                    scaleY: 0
                  }}
                  transition={{
                    duration: 1
                  }}
                  whileInView={{
                    scaleY: 1
                  }}
                  viewport={{ once: true, amount: 0.5 }}
                  className="w-full lg:col-span-2 lg:justify-self-center h-[1px] bg-ba-gray-150 lg:w-[1px] lg:h-auto min-w-0 origin-top will-change-transform"
                  role="separator"
                  aria-label="vertical-seperator"
                ></m.div>
              </DisableAnimationsMobile>
            </LazyMotion>
          )}

          <div
            className={`grid col-span-1 min-w-0 lg:col-span-6  ${verticalSeparator === 'no' ? 'lg:col-start-7' : 'lg:col-start-8 lg:col-span-5'} gap-4 grow content-start lg:mt-0 empty:hidden ${rightAlignmentClass} item-item-head-right`}
            role="region"
            aria-label="item-contents-container-right"
          >
            {renderItemComponent(itemRight)}
          </div>
        </div>
      </WrapperContainer>
    </div>
  );
};

export default ItemItemHeading;
