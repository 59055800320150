'use client';
import { ICmsImage } from '@bayada/interfaces';
import {
  CmsImage,
  DisableAnimationsMobile,
  getWrapperProps
} from '@bayada/shared/ui-components';
import React from 'react';
import { WrapperContainer } from '@bayada/shared/ui-components';
import { LazyMotion, domAnimation, m } from 'framer-motion';
import { CmsRichText } from '../cms-rich-text/cms-rich-text';
export interface BioPageProps {
  firstName: string;
  lastName: string;
  designationTitle: string;
  designation: string;
  image: ICmsImage | null;
  imageThumbnail: ICmsImage | null;
  bioDetails: BioDetails[];
  isArticleBioPage?: boolean | null;
  overview?: string;
  sys?: unknown;
}

interface BioDetails {
  heading: string;
  text: Document | null;
}

/* This code defines a functional component named `BioPage` in TypeScript React. The component takes in
props of type `BioPageProps` which includes various properties such as `firstName`, `lastName`,
`image`, `designation`, `designationTitle`, `imageThumbnail`, `overview`, `isArticleBioPage`, and
`bioDetails`. */
export const BioPage = (props: BioPageProps) => {
  const {
    firstName,
    lastName,
    image,
    designation,
    designationTitle,
    imageThumbnail,
    overview,
    isArticleBioPage,
    bioDetails
  } = props || {};
  /* This return value of the `getWrapperProps` as true or false. Based on the value, the component will render inside the `WrapperContainer` */
  const { wrapperContainer } = getWrapperProps(props);

  /* This `return` block in the `BioPage` component is rendering different content based on the value of
 `isArticleBioPage` prop. */
  return (
    <div className="flex flex-col w-full">
      {!isArticleBioPage ? (
        <div>
          <WrapperContainer
            wrapper={wrapperContainer}
            className={`relative py-12 md:py-20`}
          >
            <div className="grid w-full gap-y-9 grid-cols-12">
              <LazyMotion features={domAnimation}>
                <DisableAnimationsMobile>
                  <m.div
                    viewport={{ once: true }}
                    initial={{
                      opacity: '0',
                      transform: 'translate(0 , -10px)'
                    }}
                    transition={{
                      duration: 0.5,
                      ease: 'backInOut'
                    }}
                    whileInView={{
                      opacity: 1,
                      transform: 'translate(0px , 0px)'
                    }}
                    className="order-first w-full  col-start-1 col-end-13 sm:col-start-1 sm:col-end-4 will-change-transform"
                  >
                    {image && (
                      <div
                        className="max-w-[28.125rem] sm:max-w-full"
                        itemProp="image"
                      >
                        <CmsImage
                          imagelarge={image?.imagelarge ?? ''}
                          altText="Image description"
                          className="rounded !w-full object-contain"
                          fill={false}
                          aria-label={image?.ariaLabel}
                        />
                      </div>
                    )}
                  </m.div>
                </DisableAnimationsMobile>
              </LazyMotion>

              <LazyMotion features={domAnimation}>
                <DisableAnimationsMobile>
                  <m.div
                    initial={{
                      opacity: '0',
                      transform: 'translate(0 , 10px)'
                    }}
                    transition={{
                      duration: 0.5,
                      ease: 'backInOut'
                    }}
                    whileInView={{
                      opacity: 1,
                      transform: 'translate(0px , 0px)'
                    }}
                    viewport={{ once: true, amount: 0.5 }}
                    className=" col-start-1 col-end-13 sm:col-start-5 sm:col-end-13 flex flex-col gap-[40px] will-change-[opacity]"
                  >
                    {(firstName || lastName) && (
                      <h2
                        className="t-25-36 mb-4 font-frutiger font-bold"
                        aria-label="bio-name"
                        itemProp="name"
                      >
                        {`${firstName || ''} ${lastName || ''}`}
                      </h2>
                    )}
                    <div className="flex flex-col justify-start gap-[12px] ">
                      {designationTitle && (
                        <h2
                          className="t-16-17 font-semibold"
                          aria-label="bio-designation-title"
                        >
                          {designationTitle}
                        </h2>
                      )}

                      {designation && (
                        <p
                          className="t-16-17  color-ba-gray-900"
                          aria-label="bio-designation"
                        >
                          {designation}
                        </p>
                      )}
                    </div>
                    {bioDetails?.map((data, index) => (
                      <div key={index} className="flex flex-col gap-[12px]">
                        {data?.heading && (
                          <h2
                            className="t-16-17  font-semibold"
                            aria-label={`bio-heading-${index}`}
                          >
                            {data?.heading}
                          </h2>
                        )}
                        <div aria-label={`bio-text-${index}`}>
                          {data?.text && (
                            <CmsRichText
                              value={data?.text}
                              className="t-16-17 color-ba-gray-900 mb-0"
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </m.div>
                </DisableAnimationsMobile>
              </LazyMotion>
            </div>
          </WrapperContainer>
        </div>
      ) : (
        <div
          className="flex item-center gap-4 mb-2 lg:mb-12"
          aria-label="article-bio-container"
          itemScope
          itemType="https://schema.org/Person"
        >
          {imageThumbnail && (
            <div itemProp="image">
              <CmsImage
                {...imageThumbnail}
                width={60}
                height={60}
                style={{ borderRadius: '100%' }}
              />
            </div>
          )}

          <div className="flex flex-col justify-center gap-1">
            {(firstName || lastName) && (
              <h5
                className="t-14 mb-0 font-frutiger font-bold"
                aria-label="name-container"
                itemProp="name"
              >
                {`${firstName || ''} ${lastName || ''}`}
              </h5>
            )}
            {overview && (
              <p
                className="t-13 color-ba-gray-425 font-frutiger"
                aria-label="overview-container"
              >
                {overview || ''}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default BioPage;
